<template>
    <div>
       <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Sudionici</h5><br>
<!--                <button-->
<!--                        type="button"-->
<!--                        class="btn btn-lg btn-primary"-->
<!--                        @click.prevent="create"-->
<!--                >-->
<!--                    Novi unos-->
<!--                </button>-->
            </div>
            <div class="ibox-content">

                <div class="vld-parent">
                    <loading :active.sync="open_loader"
                             :can-cancel="false"
                             :is-full-page="true">
                    </loading>
                </div>

                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                >
                </datatables>

                <a ref="download_link"></a>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Loading from "vue-loading-overlay";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";

export default {
    name: "PoveznicaData",
    components: {
        Datatables,
        Loading,
        SkupoviNavigation
    },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'poveznica-data',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            open_loader: false
        }

    },
    methods: {
        // rowClick( row ){
        //     this.$router.push( { path: '/skupovi/' + this.skup_id + '/prijave/' + row.id } )
        // },
        //
        // create(){
        //     this.$router.push( { path: '/skupovi/' + this.skup_id + '/prijave/create' } )
        // },

        setDatatable() {
            let vm = this;

            let id = {
                'id': 'id',
                'visible': false,
            }

            let korisnik_id = {
                'id': 'korisnik_id',
                'visible': false,
                'filter':{
                    'type': 'none',
                    'value': vm.skup_id
                },
            }

            let ime = {
                'id': 'ime',
                'title': 'Ime',
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let prezime = {
                'id': 'prezime',
                'title': 'Prezime',
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let oib = {
                'id': 'oib',
                'title': 'OIB',
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let email = {
                'id': 'email',
                'title': 'E-mail',
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let table_header = [];

            table_header.push(id)
            table_header.push(korisnik_id)
            table_header.push(ime)
            table_header.push(prezime)
            table_header.push(oib)
            table_header.push(email)


            vm.datatables = {
                settings: {
                    table_header: table_header,
                    column_visibility_groups: {
                        'prijava': 'Sudionici'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Sudionici',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/poveznice-data',
                    name: 'Sudionici'
                }
            ]
        },
    },
    mounted() {
        this.setBreadcrumbs()
        if( this.skup )
            this.setDatatable()
    },
    watch:{
        skup: function (){
            this.setDatatable()
        }
    }
}
</script>

<style scoped>

</style>