<template>
    <div>
        <breadcrumbs
                :title="'Događaji'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Uredi događaj: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <skup-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                >
                </skup-forma>
            </div>
        </div>
    </div>

</template>

<script>
import SkupForma from "@/views/pages/Skupovi/SkupForma";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
export default {
name: "EventEdit",
    components: {Breadcrumbs, SkupForma},
    data(){
        return {
            id: this.$route.params.skup_id,
            breadcrumbs: [],
            naziv: '',
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi',
                    name: 'Događaji'
                },
                {
                    path: ( '/skupovi/' + this.id ),
                    name: ( 'Događaj: ' + this.naziv )
                },
                {
                    path: ( '/skupovi/' + this.id + '/edit' ),
                    name: ( 'Uredi događaj: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.setBreadcrumbs()
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>