<template>
<div>
    <breadcrumbs
            :title="'Administratori'"
            :breadcrumbs="breadcrumbs"
    ></breadcrumbs>
    <div class="ibox ">
        <div class="ibox-title">
            <h5>Novi administrator</h5>
        </div>
        <div class="ibox-content">
            <administrator-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
            >
            </administrator-forma>
        </div>
    </div>
</div>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import AdministratorForma from "@/views/pages/Administratori/AdministratorForma";

export default {
name: "AdministratorCreate",
    components: {AdministratorForma, Breadcrumbs },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/administratori',
                    name: 'Administratori'
                },
                {
                    path: '/administratori/create',
                    name: 'Novi administrator'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>