<template>
    <div>
        <div class="ibox ">
            <breadcrumbs
                    :title="'Događaji'"
                    :breadcrumbs="breadcrumbs"
            >
            </breadcrumbs>
            <div class="ibox-title">
                <h5>Događaji</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";

export default {
    name: "Events",
    components: {Breadcrumbs, Datatables},
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'skup',
            datatables: {},
            breadcrumbs: []
        }
    },
    methods: {
        rowClick( row ){
            this.$router.push( { path: '/skupovi/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/skupovi/create' } )
        },

        setDatatable(){
            let vm = this
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'naziv',
                            'title': 'Naziv',
                            'column_visibility_group': {
                                id: 'skup',
                            }
                        },
                        {
                            'id': 'vrsta_skupova.naziv',
                            'title': 'Vrsta skupa',
                            'column_visibility_group': {
                                id: 'skup',
                            }
                        },
                        {
                            'id': 'datum_od',
                            'title': 'Početak skupa',
                            'column_visibility_group': {
                                id: 'skup',
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'datum_do',
                            'title': 'Završetak skupa',
                            'column_visibility_group': {
                                id: 'skup',
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'datum_prijave_od',
                            'title': 'Početak prijava',
                            'column_visibility_group': {
                                id: 'skup',
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'datum_prijave_do',
                            'title': 'Završetak prijava',
                            'column_visibility_group': {
                                id: 'skup',
                            },
                            'filter': {
                                'type': 'date',
                            }
                        }
                    ],
                    column_visibility_groups:{
                        'skup' : 'Skup'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Skupovi',
            }
            vm.show_datatable = true
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi',
                    name: 'Skupovi'
                }
            ];
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }


}
</script>

<style scoped>

</style>