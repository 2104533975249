<template>
    <div>
        <skupovi-navigation
                :id="id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>


        <div v-show="show_skup" class="ibox ">
            <div class="ibox-title">
                <h5>Događaj: {{ naziv }} </h5>
            </div>
            <div class="ibox-content">
                <skup-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                >
                </skup-forma>
            </div>
        </div>
    </div>

</template>

<script>
import SkupForma from "@/views/pages/Skupovi/SkupForma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
name: "EventDetails",
    components: {SkupoviNavigation, SkupForma},
    data(){
        return {
            id: this.$route.params.skup_id,
            breadcrumbs: [],
            naziv: '',
            show_skup: true,
            skup: null,
        }
    },
    methods:{
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.skup = data
        },

    },
    mounted() {
    }
}
</script>

<style scoped>

</style>