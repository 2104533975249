<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Tema</h5>
            </div>
            <div class="ibox-content">
                <skup-tema-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                >
                </skup-tema-forma>
            </div>
        </div>
    </div>
</template>

<script>
import SkupTemaForma from "@/views/pages/Skupovi/SkupoviTeme/SkupTemaForma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
name: "SkupTemaCreate",
    components: {
        SkupTemaForma,
        SkupoviNavigation
    },
    data(){
        return {
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            skup: {}
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/teme',
                    name: 'Teme'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/teme/create',
                    name: 'Nova tema'
                }
            ]
        },

    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>