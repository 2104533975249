<template>
    <div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        >
        </forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Forms from "@/components/form/Forms";
export default {
    name: "SkupTemaForma",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/skupovi/' + this.$route.params.skup_id + '/podteme',
            url: 'skup-tema2',
            data: {},
            skup_id: this.$route.params.skup_id,


            teme_select: [],
        }
    },

    methods: {

        async fetchTeme(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'skup-tema', {
                params: {
                    filters: {
                        skup_id: vm.skup_id
                    }
                }
            } )
            return response.data.data.map( function( item ) {
                vm.teme_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        getTema( id ){
            return this.teme_select.find( item => item.key === id )
        },

        async init(){
            let vm = this

            await vm.fetchTeme()

            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {

                            vm.data.skup_tema_id = response.data.skup_tema_id.toString()
                            vm.data.izzy_edu_id = response.data.izzy_edu_id ? response.data.izzy_edu_id.toString() : ''
                            vm.data.broj_teme2 = response.data.broj_teme2
                            vm.data.naziv = response.data.naziv
                            vm.data.datum_od = response.data.datum_od
                            vm.data.datum_do = response.data.datum_do
                            vm.data.predavaci = response.data.predavaci

                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let skup_tema_id_input = {
                name: 'skup_tema_id',
                type: 'select',
                title: 'Tema',
                value: vm.getTema( vm.data.skup_tema_id ),
                width: 12,
                error: vm.errors['skup_tema_id'] ? vm.errors['skup_tema_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.teme_select,
                    multiple: false,
                }
            }

            let izzy_edu_id_input = {
                name: 'izzy_edu_id',
                type: 'hidden',
                value: vm.data.izzy_edu_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }

            let broj_teme2_input = {
                name: 'broj_teme2',
                type: 'input',
                title: 'Broj Podteme',
                value: vm.data['broj_teme2'],
                width: 12,
                error: vm.errors['broj_teme2'] ? vm.errors['broj_teme2'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
            }

            let naziv_input = {
                name: 'naziv',
                type: 'input',
                title: 'Naziv',
                value: vm.data['naziv'],
                width: 12,
                error: vm.errors['naziv'] ? vm.errors['naziv'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
            }

            let datum_od_input = {
                name: 'datum_od',
                type: 'datepicker',
                title: 'Početak događaja',
                value: vm.data['datum_od'],
                width: 12,
                error: vm.errors['datum_od'] ? vm.errors['datum_od'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode:'dateTime',
                    title_width: 4,
                    input_width: 4
                }
            }

            let datum_do_input = {
                name: 'datum_do',
                type: 'datepicker',
                title: 'Završetak događaja',
                value: vm.data['datum_do'],
                width: 12,
                error: vm.errors['datum_do'] ? vm.errors['datum_do'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode:'dateTime',
                    title_width: 4,
                    input_width: 4
                }
            }

            let predavaci_input = {
                name: 'predavaci',
                type: 'input',
                title: 'Predavači',
                value: vm.data['predavaci'],
                width: 12,
                error: vm.errors['predavaci'] ? vm.errors['predavaci'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
            }

            let inputs = []
            inputs.push(skup_tema_id_input)
            inputs.push(izzy_edu_id_input)
            inputs.push(broj_teme2_input)
            inputs.push(naziv_input)
            inputs.push(datum_od_input)
            inputs.push(datum_do_input)
            inputs.push(predavaci_input)


            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
