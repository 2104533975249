<template>
    <div>

        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>

        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Emailing</h5><br>
            </div>
            <div class="ibox-content">
                <forms
                    :settings="settings"
                    @onFormButtonClick="onFormButtonClick"
                    @onInput="onInput"
                ></forms>
            </div>
        </div>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Emailing</h5><br>
                <button class="btn btn-primary" @click="sendMail('all')">Pošalji sve</button>
                <button class="btn btn-danger" @click="deleteMail('all')">Obriši sve</button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @buttonClick="datatableButtonClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
import Forms from "../../../../components/form/Forms";
import axios from "axios";
import Loading from "vue-loading-overlay";
import toastr from "toastr";
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name: "Emailing",
    components: { SkupoviNavigation, Datatables, Forms, Loading },
    data(){
        return {
            skup_id: this.$route.params.skup_id,
            id: this.$route.params.email_id,

            open_loader: false,

            // datatable
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'emailing',
            datatables: {},
            breadcrumbs: [],

            // form
            settings: {},
            errors: {},
            data: {
                prijava_osoba_ids: []
            },

            // select options
            osobe_select: []
        }
    },
    methods:{

        async init(){
            this.open_loader = true
            await this.fetchOsobe()
            this.setupForm()
            this.setDatatable()
            this.setBreadcrumbs()
            this.open_loader = false
        },


        async fetchOsobe(){
            let vm = this
            await axios.get( process.env.VUE_APP_API_URL + 'prijava-osoba', {
                params:{
                    page: 1,
                    per_page: -1,
                    filters: {
                        skup_id: vm.skup_id,
                        vrsta_kotizacije_id: 1
                    }
                }
            } )
                .then( response => {

                    vm.osobe_select.push( {
                        'key': 'all_placeno',
                        'value': 'Svi sa uplačenim kotizacijama'
                    } )

                    vm.osobe_select.push( {
                        'key': 'all',
                        'value': 'Svi prijavljeni'
                    } )


                    response.data.data.forEach( item => {
                        vm.osobe_select.push( {
                            key: item.id.toString(),
                            value: item.ime + ' ' + item.prezime + ' (' + item.oib + ')'
                        } )
                    })
                } )
                .catch( error => console.log( error ) )
        },


        getOsoba( ids ){
            let vm = this
            let ret = []
            ids.forEach( id => {
                let tmp = vm.osobe_select.find( item => item.key === id )
                if( tmp )
                    ret.push( tmp )
            } )
            return ret
        },


        setupForm(){

            let vm = this

            let cancel_button = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
            }

            let generate_button = {
                id: 'add',
                title: 'Dodaj',
                type: 'primary',
            }

            let buttons = []
            buttons.push( cancel_button )
            if(
                vm.data.prijava_osoba_ids
                &&
                vm.data.prijava_osoba_ids.length
            )
                buttons.push( generate_button )



            let prijava_osoba_ids_input = {
                name: 'prijava_osoba_ids',
                type: 'select',
                title: 'Osobe',
                value: vm.getOsoba( vm.data.prijava_osoba_ids || [] ),
                width: 12,
                error: vm.errors['prijava_osoba_ids'] ? vm.errors['prijava_osoba_ids'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.osobe_select,
                    title_width: 2,
                    input_width: 10,
                    multiple: true
                }
            }

            let inputs = []
            inputs.push( prijava_osoba_ids_input )

            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            },
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        onInput( name, value ){
            let vm = this;

            if( vm.data[name] !== value ){
                if( value.includes( 'all') ){
                    vm.data[name] = ['all']
                } else if( value.includes( 'all_placeno') ){
                    vm.data[name] = ['all_placeno']
                } else {
                    vm.data[name] = value
                }
                vm.setupForm()
            }
        },


        onFormButtonClick( id, action, config, data ){
            let vm = this
            if( id === 'add' ){
                vm.open_loader = true
                axios.get( process.env.VUE_APP_API_URL + 'emailing/' + vm.id + '/add-to-mailing-list' , {
                    params: {
                        ...data
                    }
                } )
                    .then( response => {
                        if( response.data.success ){
                            toastr.success( 'Uspješno kreirano!' )
                            vm.data = {}
                            vm.setupForm()
                            vm.setDatatable()
                            vm.open_loader = false
                        } else {
                            if( response.data.messages && response.data.messages.general ){
                                response.data.messages.general.forEach( item => {
                                    toastr.error( item )
                                } )
                            }
                            else{
                                toastr.error( 'Došlo je do greške!' )
                            }
                            vm.open_loader = false
                        }
                    } )
                    .catch( error => {
                        console.log( error )
                        vm.open_loader = false
                        toastr.error( 'Došlo je do greške!' )
                    } )
            } else if ( id === 'cancel' ){
                vm.data = {}
                vm.setupForm()
            }
        },



        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'email_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.id
                            },
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'type' : function( row ){
                                return row.prijave_osobe ? row.prijave_osobe.ime : ''
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'type' : function( row ){
                                return row.prijave_osobe ? row.prijave_osobe.prezime : ''
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'oib',
                            'title': 'OIB',
                            'type' : function( row ){
                                return row.prijave_osobe ? row.prijave_osobe.oib : ''
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'type' : function( row ){
                                return row.prijave_osobe ? row.prijave_osobe.email : ''
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'send',
                            'title': '',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'emailing',
                            },
                            config: {
                                title: 'Pošalji',
                                type: 'primary',
                            }
                        },
                        {
                            'id': 'delete',
                            'title': '',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'emailing',
                            },
                            config: {
                                title: 'Obriši',
                                type: 'danger',
                            }
                        },
                        {
                            'id': 'potvrda_slanja',
                            'title': 'Potvrđeno slanje',
                            'type' : function( row ){
                                return row.potvrda_slanja ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    },
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },

                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'poslano',
                            'title': 'Poslano',
                            'type' : function( row ){
                                return row.poslano ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    },
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },

                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'uspjesno',
                            'title': 'Uspješno poslano',
                            'type' : function( row ){
                                return row.uspjesno ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    },
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },

                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'datum_i_vrijeme_slanja',
                            'title': 'Datum',
                            'type' : 'text',
                            'searchable': false,
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'log',
                            'title': 'Log',
                            'searchable': false,
                            'type' : function( row ){
                                return row.log
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        }

                    ],
                    column_visibility_groups:{
                        'emailing' : 'Emailing'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Emailing',
            }
            vm.show_datatable = true;
        },


        async datatableButtonClick( col, row ){
            if( col.id === 'send' ){
                if( row.poslano ){
                    toastr.error( 'Email je već poslan!')
                } else {
                    await this.sendMail( row.id )
                }
            } else if( col.id === 'delete' ){
                if( row.poslano ){
                    toastr.error( 'Email je već poslan i ne može se brisati!')
                } else {
                    await this.deleteMail( row.id )
                }
            }
        },

        async sendMail( id ){
            let vm = this;
            vm.open_loader = true;
            await axios.get( process.env.VUE_APP_API_URL + 'emailing/' + vm.id + '/' + 'send/' + id )
                .then( response => {
                    console.log( response )
                    vm.setDatatable()
                    vm.open_loader = false;
                })
                .catch( error => {
                    console.log( error )
                    vm.open_loader = false;
                })
        },

        async deleteMail( id ){
            console.log( id )
            let vm = this;
            vm.open_loader = true;
            await axios.get( process.env.VUE_APP_API_URL + 'emailing/' + vm.id + '/' + 'delete/' + id )
                .then( response => {
                    console.log( response )
                    vm.setDatatable()
                    vm.open_loader = false;
                })
                .catch( error => {
                    console.log( error )
                    vm.open_loader = false;
                })
        },


        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/emails',
                    name: 'Obavijesti'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/emails/' + this.id + '/emailing',
                    name: 'Slanje obavijesti'
                }
            ]
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
