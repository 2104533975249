<template>
    <div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
        >
        </forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Forms from "@/components/form/Forms";
export default {
name: "SkupDodatniSadrzajForma",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/skupovi/' + this.$route.params.skup_id + '/dodatni-sadrzaji',
            url: 'skup-dodatni-sadrzaj',
            data: {},
            skup_id: this.$route.params.skup_id
        }
    },

    methods: {

        init(){
            let vm = this
            if ( vm.action === 'edit' || vm.action === 'show' ){
                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {
                            vm.data = response.data
                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'skup_id',
                                        type: 'hidden',
                                        value: vm.skup_id,
                                        disabled: vm.disabled,
                                        config: {
                                            hidden:true
                                        }
                                    },
                                    {
                                        name: 'dodatni_sadrzaj_id',
                                        type: 'select',
                                        title: 'Dodatni sadržaj',
                                        value: {
                                            key: vm.data['dodatni_sadrzaj_id'] ? vm.data['dodatni_sadrzaj_id'] : '',
                                            value: vm.data['dodatni_sadrzaji'] ? (vm.data['dodatni_sadrzaji']['naziv'] ? vm.data['dodatni_sadrzaji']['naziv'] + ' | ' + vm.data['dodatni_sadrzaji']['opis'] : '') : ''
                                        },
                                        width: 12,
                                        error: vm.errors['dodatni_sadrzaj_id'] ? vm.errors['dodatni_sadrzaj_id'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: function ( ) {
                                                let model = this

                                                if( vm.action === 'show'){
                                                    model.options = []
                                                    return
                                                }

                                                axios.get( process.env.VUE_APP_API_URL + 'dodatni-sadrzaj' )
                                                        .then( function ( response ) {
                                                            let tmp = [];
                                                            response.data.data.forEach( function ( item ) {
                                                                tmp.push({
                                                                    key: item.id,
                                                                    value: item.naziv + ' | ' + item.opis
                                                                } );
                                                            } );
                                                            model.options = tmp
                                                        } )
                                                        .catch( function ( error ) {
                                                            if(error instanceof Error) {
                                                                console.log( 'Error: ' . error);
                                                            } else {
                                                                console.log( 'Unexpected response: ' . error);
                                                            }
                                                        } )
                                            },
                                            multiple: false,
                                        }
                                    },
                                    {
                                        name: 'cijena',
                                        type: 'input',
                                        title: 'Cijena',
                                        value: vm.data['cijena'] ? vm.data['cijena'] : '',
                                        width: 12,
                                        error: vm.errors['cijena'] ? vm.errors['cijena'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled || vm.action === 'edit',
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                    },
                                    {
                                        name: 'porez_id',
                                        type: 'select',
                                        title: 'Porez',
                                        value: {
                                            key: vm.data['porez_id'] ? vm.data['porez_id'] : '',
                                            value: vm.data['porezi'] ? (vm.data['porezi']['postotak'] ? vm.data['porezi']['postotak'] : '') : ''
                                        },
                                        width: 12,
                                        error: vm.errors['porez_id'] ? vm.errors['porez_id'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled || vm.action === 'edit',
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: function ( ) {
                                                let model = this

                                                if( vm.action === 'show'){
                                                    model.options = []
                                                    return
                                                }

                                                axios.get( process.env.VUE_APP_API_URL +  'porez' )
                                                        .then( function ( response ) {
                                                            let tmp = [];
                                                            response.data.data.forEach( function ( item ) {
                                                                tmp.push({
                                                                    key: item.id,
                                                                    value: item.postotak
                                                                } );
                                                            } );
                                                            model.options = tmp
                                                        } )
                                                        .catch( function ( error ) {
                                                            if(error instanceof Error) {
                                                                console.log( 'Error: ' . error);
                                                            } else {
                                                                console.log( 'Unexpected response: ' . error);
                                                            }
                                                        } )
                                            },
                                            multiple: false,
                                        }
                                    },
                                    {
                                        name: 'napomena',
                                        type: 'input',
                                        title: 'Napomena',
                                        value: vm.data['napomena'] ? vm.data['napomena'] : '',
                                        width: 12,
                                        error: vm.errors['napomena'] ? vm.errors['napomena'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled || vm.action === 'edit',
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                    },
                                ],
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>