<template>
   <div>
       <skupovi-navigation
               :id="skup_id"
               :breadcrumbs="breadcrumbs"
       >
       </skupovi-navigation>
       <div class="ibox ">
           <div class="ibox-title">
               <h5>Prijava: {{ naziv }}</h5>
           </div>
           <div class="ibox-content">
               <prijava-forma
                       :disabled="true"
                       :id="id"
                       :modal="false"
                       :action="'show'"
                       @returnData="setData"
                       :skup="skup"
               >
               </prijava-forma>
           </div>
       </div>
   </div>
</template>

<script>
import PrijavaForma from "@/views/pages/Skupovi/Prijave/PrijavaForma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
name: "PrijavaDetails",
    components: {
        PrijavaForma,
        SkupoviNavigation
    },
    props:[],
    data(){
        return {
            breadcrumbs: [],
            id:this.$route.params.prijava_id,
            skup_id: this.$route.params.skup_id,
            skup: {},
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/prijave',
                    name: 'Prijeve'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/prijave/' + this.id,
                    name: 'Prijava: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.prijave_osobe[0].ime + ' ' + data.prijave_osobe[0].prezime + ' | ' + data.prijave_osobe[0].oib +
                    ', ' + 'Broj prijave: ' + data.broj_prijave
            this.setBreadcrumbs()
        },
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>