<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>

        <div class="ibox ">
            <div class="ibox-title">
                <h5>Webinari</h5>
            </div>
            <div class="ibox-content">
                <webinar-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                >
                </webinar-forma>
            </div>
        </div>
    </div>
</template>

<script>
import WebinarForma from "@/views/pages/Skupovi/Webinars/WebinarForma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
name: "WebinarCreate",
    components: {
        WebinarForma,
        SkupoviNavigation
    },
    data(){
        return {
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/webinari',
                    name: 'Webinari'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/webinari/create',
                    name: 'Novi webinar'
                }
            ]
        },

    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>