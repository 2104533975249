<template>
    <div>
        <breadcrumbs
            :title="'Vrste suorganizatora'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Vrsta suorganizatora: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <vrsta-suorganizatora-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                ></vrsta-suorganizatora-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import VrstaSuorganizatoraForma from "./VrstaSuorganizatoraForma";
export default {
    name: "VrstaSuorganizatoraDetails",
    components: {
        VrstaSuorganizatoraForma,
        Breadcrumbs
    },
    data(){
        return {
            id: this.$route.params.vrsta_suorganizatora_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/vrste-suorganizatora',
                    name: 'Vrste suorganizatora'
                },
                {
                    path: '/postavke/vrste-suorganizatora/' + this.id,
                    name: 'Vrsta suorganizatora: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv || ''
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>
