<template>
    <div class="row">

        <!-- loader -->
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>

        <!-- Results per page -->
        <result-per-page
            :results_per_page="results_per_page"
            @setNumberOfRows="setNumberOfRows"
        ></result-per-page>

        <!-- Showed results -->
        <result-counter
            :showed_results="showed_results"
        >
        </result-counter>

        <!-- Show/Hide column -->
        <column-visibility
            :column_visibility_groups="column_visibility_groups"
            :table_header="table_header"
            @setColumnVisibility="setColumnVisibility"
        >
        </column-visibility>

        <!-- Export button -->
        <export
            v-if="enable_export"
            :url="url"
            :title="title"
            :order_by="order_by"
            :order="order"
            :filters="filters"
            :options="options"
            :headers="table_header "
        >
        </export>

        <!-- Pagination -->
        <pagination
            :pagination="pagination"
            @setPage="setPage"
        >
        </pagination>

        <!-- Table -->
        <div class="table-responsive" style="min-width: 100%" >
            <table class="table table-hover">
                <thead class="thead-light table-bordered">
                <!-- Header -->
                <column-titles
                    :table_header="table_header"
                    :order_by="order_by"
                    :order="order"
                    @orderBy="orderBy"
                    @move="move"
                >
                </column-titles>

                <!-- Filters -->
                <column-filters
                    :table_header="table_header"
                    @setFilters="setFilter"
                >
                </column-filters>
                </thead>

                <!-- Content -->
                <table-data
                    :table_header="table_header"
                    :data="data"
                    @rowClick="rowClick"
                    @buttonClick="buttonClick"
                    @checkboxHandler="checkboxHandler"
                    @inputHandler="inputHandler"
                    @selectHandler="selectHandler"
                    @multiselectHandler="multiselectHandler"
                    @switchHandler="switchHandler"
                >
                </table-data>

                <!-- Footer -->
                <tfoot class="thead-light table-bordered">
                <column-titles
                    :table_header="table_header"
                    :order_by="order_by"
                    :order="order"
                    @orderBy="orderBy"
                    @move="move"
                >
                </column-titles>
                </tfoot>

            </table>
        </div>

        <!-- Pagination -->
        <pagination
            :pagination="pagination"
            @setPage="setPage"
        >
        </pagination>

    </div>
</template>

<script>

import ResultPerPage from "@/components/datatable/parts/ResultPerPage";
import ResultCounter from "@/components/datatable/parts/ResultCounter";
import ColumnVisibility from "@/components/datatable/parts/ColumnVisibility";
import Export from "@/components/datatable/parts/Export";
import Pagination from "@/components/datatable/parts/Pagination";
import ColumnTitles from "@/components/datatable/parts/ColumnTitles";
import ColumnFilters from "@/components/datatable/parts/ColumnFilters";
import TableData from "@/components/datatable/parts/TableData";
import axios from "axios";
import Loading from "vue-loading-overlay";
//import qs from "qs"

export default {
    name: "Datatables",
    components:{
        ResultPerPage,
        ResultCounter,
        ColumnVisibility,
        Export,
        Pagination,
        ColumnTitles,
        ColumnFilters,
        TableData,
        Loading
    },
    props:{
        url: {
            type: String,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
    },
    data(){
        return {

            // loader
            open_loader: false,

            // column headers
            // table filters
            table_header:[],

            // export buttons
            enable_export: true,

            // results per page
            results_per_page:{
                list: {
                    '10': '10',
                    '25': '25',
                    '50': '50',
                    '100': '100',
                    '-1': 'Sve'
                },
                selected : '50',
            },

            // showed results
            showed_results:{
                from: 0,
                to: 0,
                total: 0
            },

            // column visibility
            column_visibility_groups:{},

            // pagination
            pagination: {
                current_page: 1,
                number_of_pages: 0,
            },



            // column filters
            filters:{},

            // options
            options:{},

            // table data
            data:[],


            // general settings
            order_by: '',
            order: 'desc',




        }
    },
    methods:{

        // setup datatable
        async setupDatatable(){

            let vm = this;

            // setup global order
            this.order = this.$props.order ? this.$props.order : 'desc'
            this.order_by = this.$props.order_by ? this.$props.order_by : 'id'

            // results per page settings
            if( this.$props.settings.results_per_page ){
                if( this.$props.settings.results_per_page.list )
                    this.results_per_page.settings.list = this.$props.settings.results_per_page.list
                if( this.$props.settings.results_per_page.selected ){
                    this.results_per_page.selected = this.$props.settings.results_per_page.selected
                }
            }

            // enable export
            vm.enable_export = this.$props.settings.enable_export !== undefined ? this.$props.settings.enable_export : true

            // setup col vis group
            if( vm.$props.settings.column_visibility_groups )
                for( let item in vm.$props.settings.column_visibility_groups )
                    vm.column_visibility_groups[item] = {
                        title: vm.$props.settings.column_visibility_groups[item],
                        columns: []
                    }


            vm.column_visibility_groups['__default__'] = {
                title: '',
                columns: []
            }

            vm.table_header = []
            // setup table headers
            vm.$props.settings.table_header.forEach( function ( item, index ) {
                vm.table_header.push( {
                    reload : false, // varijabla služi samo za refreshanje objekta
                    id: item.id ? item.id : index,
                    title: item.title ? item.title : null,
                    type: item.type ? item.type : 'text',
                    visible: item.visible === undefined || item.visible === true,
                    orderable: ( item.orderable === undefined || item.orderable === true ),
                    searchable: item.searchable === undefined || item.searchable === true,
                    column_visibility_group: {
                        id: item.column_visibility_group ? item.column_visibility_group.id : '__default__',
                        visible: ( item.visible === undefined || item.visible === true )
                            && ( item.column_visibility_group.visible === undefined || item.column_visibility_group.visible === true )
                    },
                    filter: {
                        type: (item.filter && item.filter.type) ? item.filter.type : 'input',
                        search: (item.filter && item.filter.search) ? item.filter.search : [],
                        value: (item.filter && item.filter.value) ? item.filter.value : null,
                    },

                    config: item.config ? item.config : {}
                } )

                if( item.filter && item.filter.value ){
                    vm.filters[item.id] = item.filter.value
                }

                if( item.options ){
                    vm.options[item.id] = item.options
                }

            } )

            // get data
            console.log( 'setupDatatable' )
            await this.getData()
        },

        async getData( page = null, per_page = null, order_by = null, order = null, filters = [], options = [] ){
            let vm = this

            vm.open_loader = true;

            let _filters = filters.length ? filters  : vm.filters;
            let _options = options.length ? options : vm.options;

            await axios.get( vm.url, {
                params: {
                    page: page ? page : this.pagination.current_page,
                    per_page: per_page ? per_page : this.results_per_page.selected,
                    order_by: order_by ? order_by : this.order_by,
                    order: order ? order : this.order,
                    filters: _filters,
                    options: _options,
                }
            } )
                .then( function ( response ) {

                    console.log( _filters )
                    console.log( response.data )

                    // set results per page
                    vm.showed_results.from = parseInt( response.data.results_from ? response.data.results_from : 0 )
                    vm.showed_results.to = parseInt( response.data.results_to ? response.data.results_to : 0 )
                    vm.showed_results.total = parseInt( response.data.filtered ? response.data.filtered : 0 )

                    // set pagination
                    vm.pagination.current_page = parseInt( response.data.page ? response.data.page : 1 )
                    vm.pagination.number_of_pages = parseInt( response.data.pages ? response.data.pages : 0 )

                    // print data
                    vm.data = response.data.data ? response.data.data : [];

                    vm.open_loader = false;
                } )
                .catch( function ( error ) {
                    vm.open_loader = false;
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } )
        },

        // results per page
        async setNumberOfRows( key ){
            this.pagination.current_page = 1
            this.results_per_page.selected = key

            console.log( 'setNumberOfRow' )
            await this.getData()

        },

        // show/hide column
        setColumnVisibility( group_id, col_id ){
            this.table_header.forEach( function( item ) {
                if ( col_id && item.id === col_id && item.column_visibility_group.id === group_id ){
                    item.reload = !item.reload
                    item.column_visibility_group.visible = !item.column_visibility_group.visible
                } else if( !col_id && item.column_visibility_group.id === group_id ) {
                    item.reload = !item.reload
                    item.column_visibility_group.visible = !item.column_visibility_group.visible
                }
            })
        },

        // export

        // pagination
        async setPage( no ){
            this.pagination.current_page = no
            console.log( 'setPage')
            await this.getData()
        },

        // headers
        async orderBy( id, order ){
            this.order_by = id
            this.order = order
            console.log( 'orderBy' )
            await this.getData()
        },

        move( id, direction ){
            let left_index = null;
            let right_index = null;
            let flag = false;

            for( let index in this.table_header ){
                if( this.table_header[index].id == id ){
                    if( direction === 1 ){
                        left_index = index
                        flag = true
                    } else {
                        right_index = index
                        flag = true
                    }
                } else if ( this.table_header[index].visible && this.table_header[index].column_visibility_group.visible  ) {
                    if( direction === 1 && flag ){
                        right_index = index
                        break;
                    } else if ( flag ) {
                        break;
                    } else {
                        left_index = index
                    }
                }
            }

            if( flag && left_index && right_index ){
                this.table_header[left_index].reload = !this.table_header[left_index].reload
                this.table_header[right_index].reload = !this.table_header[right_index].reload
                let tmp = this.table_header[ left_index ]
                this.table_header[ left_index ] = this.table_header[ right_index ]
                this.table_header[ right_index ] = tmp
            }

        },


        // filters
        async setFilter( args ){
            if( args.value ){
                if( this.filters[args.key] != args.value ){
                    this.filters[args.key] = args.value
                    await this.getData()
                }
            }
            else if( this.filters[args.key] ) {
                delete this.filters[args.key]
                await this.getData()
            }

        },


        // row click handler
        rowClick( row ){
            this.$emit( 'rowClick', row, this )
        },

        // buttons handler
        buttonClick( col, row ){
            this.$emit( 'buttonClick', col, row, this )
        },

        // checkbox handler
        checkboxHandler( col, row, value ){
            this.$emit( 'checkboxHandler', col, row, value, this )
        },

        // input handler
        inputHandler( col, row, value ){
            this.$emit( 'inputHandler', col, row, value, this )
        },

        // select handler
        selectHandler( col, row, value ){
            this.$emit( 'selectHandler', col, row, value, this )
        },

        // multiselect handler
        multiselectHandler( col, row, value ){
            this.$emit( 'multiselectHandler', col, row, value, this )
        },

        // switch handler
        switchHandler( col, row, value ){
            this.$emit( 'switchHandler', col, row, value, this )
        }

    },
    mounted() {
        this.setupDatatable()
    },
    watch:{
        settings: {
            deep: true,
            handler: function(){
                this.setupDatatable()
            }
        },
        title: {
            deep: true,
            handler: function(){
                this.setupDatatable()
            }
        },
        url: {
            deep: true,
            handler: function(){
                this.setupDatatable()
            }
        }
    },
}
</script>

<style scoped>

</style>
