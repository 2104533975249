<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Obavjest: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <email-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                >
                </email-forma>
            </div>
        </div>
    </div>
</template>

<script>
import EmailForma from "@/views/pages/Skupovi/Emails/EmailForma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
    name: "EmailDetails",
    components:{
        EmailForma,
        SkupoviNavigation
    },
    data(){
        return {
            breadcrumbs: [],
            id:this.$route.params.email_id,
            skup_id: this.$route.params.skup_id,
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/emails',
                    name: 'Obavijesti'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/emails/' + this.id,
                    name: 'Obavjest: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naslov
            this.setBreadcrumbs()
        },

    },
    mounted() {
    }
}
</script>

<style scoped>

</style>
