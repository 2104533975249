import { render, staticRenderFns } from "./SkupSuorganizatorBase.vue?vue&type=template&id=051b163c&scoped=true&"
import script from "./SkupSuorganizatorBase.vue?vue&type=script&lang=js&"
export * from "./SkupSuorganizatorBase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051b163c",
  null
  
)

export default component.exports