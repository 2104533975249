<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Obavijesto</h5><br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Nova obavijest
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                    @buttonClick="buttonClick"
                >
                </datatables>
            </div>
        </div>
    </div>

</template>

<script>

import Datatables from "@/components/datatable/Datatables";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
import axios from "axios";

export default {
    name: "Emails",
    components: {
        Datatables,
        SkupoviNavigation
    },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'email',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            skup: null
        }
    },
    methods:{
        getSkup(){
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'skup' + '/' + vm.skup_id )
                .then( function ( response ) {
                    vm.skup = response.data
                    vm.setDatatable()
                } )
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } )
        },
        rowClick( row ){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/emails/' + row.id + '/edit' } )
        },

        create(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/emails/create' } )
        },

        setDatatable() {

            let vm = this;

            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.skup_id
                            },
                        },

                        {
                            'id': 'naslov',
                            'title': 'Naslov',
                            'column_visibility_group': {
                                id: 'emails',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'emails',
                            },
                            config: {
                                title: 'Email',
                                type: 'success',
                            }
                        }
                    ],
                    column_visibility_groups:{
                        'emails' : 'Obavijesti'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Obavijesti',
            }
            vm.show_datatable = true;
        },


        buttonClick( col, row ){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/emails/' + row.id + '/emailing' } )
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/emails',
                    name: 'Obavijesti'
                }
            ]
        }
    },
    mounted() {
        this.getSkup()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
