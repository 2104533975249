<template>
    <div>
        <breadcrumbs
            :title="'Vrste suorganizatora'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova vrsta suorganizatora</h5>
            </div>
            <div class="ibox-content">
                <vrsta-suorganizatora-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></vrsta-suorganizatora-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import VrstaSuorganizatoraForma from "./VrstaSuorganizatoraForma";
export default {
    name: "VrstaSuorganizatoraCreate",
    components: {
        VrstaSuorganizatoraForma,
        Breadcrumbs
    },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/vrste-suorganizatora',
                    name: 'Vrste suorganizatora'
                },
                {
                    path: '/postavke/vrste-suorganizatora/create',
                    name: 'Nova vrsta suorganizatora'
                }
            ]
        }
    },
    mounted(){
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
