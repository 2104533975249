<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Prijave</h5>
            </div>
            <div class="ibox-content">
                <forma28-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                >
                </forma28-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Forma28Forma from "@/views/pages/Skupovi/FormeData/28/Forma28Forma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
    name: "FormaDataCreate",
    components: {
        Forma28Forma,
        SkupoviNavigation
    },
    data(){
        return {
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/forma-data',
                    name: 'Sudionici'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/forma-data/create',
                    name: 'Novi unos'
                }
            ]
        },

    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>