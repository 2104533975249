<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Teme</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
import axios from "axios";

export default {
    name: "SkupoviTeme",
    components: {
        Datatables,
        SkupoviNavigation
    },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'skup-tema',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id
        }

    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/teme/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/teme/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.skup_id
                            },
                        },
                        {
                            'id': 'broj_teme',
                            'title': 'Broj teme',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'tema',
                            }
                        },
                        {
                            'id': 'naziv',
                            'title': 'Naziv',
                            'column_visibility_group': {
                                id: 'tema',
                            }
                        },
                        {
                            'id': 'predavaci',
                            'searchable': false,
                            'title': 'Predavači',
                            'column_visibility_group': {
                                id: 'tema',
                            }
                        },
                        {
                            'id': 'datum_od',
                            'title': 'Početak',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'tema',
                             }
                        },
                        {
                            'id': 'datum_do',
                            'title': 'Završetak',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'tema',
                            }
                        },
                        {
                            'id': 'dvorana_id',
                            'title': 'Dvorane',
                            'type' : function( row ){
                                return row.dvorana_id ? row.dvorane.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'dvorana', {
                                        params: {
                                            filters: {
                                                skup_id: vm.skup_id
                                            }
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'tema',
                                'visible' : true
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'tema' : 'Teme'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Teme',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/teme',
                    name: 'Teme'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
