<template>
    <div>

        <component
                :is="'forma' + skup_id"
                :name="skup_id"
        ></component>
    </div>
</template>

<script>

import Forma28 from "./28/Forma28";
import Forma31 from "./31/Forma31";
import Forma49 from "./49/Forma49";
import Forma50 from "./50/Forma50";

export default {
    name: "FormaData",
    components: {
        Forma28,
        Forma31,
        Forma49,
        Forma50
    },
    data(){
        return {
            skup_id: this.$route.params.skup_id,
        }
    }

}
</script>

<style scoped>

</style>
