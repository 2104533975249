<template>
    <div>
        <breadcrumbs
                :title="'Notifikacije'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Notifikacije</h5><br>
            </div>
            <div class="ibox-content">
                <notifikacija-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                ></notifikacija-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import NotifikacijaForma from "@/views/pages/Notifikacije/NotifikacijaForma";
export default {
name: "NotifikacijaCreate",
    components: { NotifikacijaForma, Breadcrumbs},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/notifikacije',
                    name: 'Notifikacije'
                },
                {
                    path: '/notifikacije/create',
                    name: 'Nova notifikacija'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>