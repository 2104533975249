<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Suorganizatori</h5>
            </div>
            <div class="ibox-content">
                <skup-suorganizator-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                >
                </skup-suorganizator-forma>
            </div>
        </div>
    </div>
</template>

<script>
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
import SkupSuorganizatorForma from "./SkupSuorganizatorForma";
export default {
    name: "SkupSuorganizatorCreate",
    components: {
        SkupSuorganizatorForma,
        SkupoviNavigation
    },
    data(){
        return {
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            skup: {}
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/suorganizatori',
                    name: 'Suorganizatori'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/suorganizatori/create',
                    name: 'Novi suorganizator'
                }
            ]
        },
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
