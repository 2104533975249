<template>
    <div class="container">
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>

        <div class="row" style="margin-top: 10%">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">Prijava</div>

                    <div class="panel-body">
                        <div class="col-md-12" style="text-align: center">
                            <img src="@/img/app/izzyedu.png" style="align-items: center">
                            <div>
                                <h1>
                                    Prijave - Administracija
                                </h1>
                                <br>
                            </div>
                        </div>

                        <form class="form-horizontal">

                            <div :class="{'form-group': true}">

                                <label
                                        for="email"
                                        class="col-md-4 control-label"
                                >
                                    E-Mail / Korisničko ime
                                </label>

                                <div class="col-md-12">
                                    <input
                                            id="email"
                                            type="text"
                                            class="form-control"
                                            name="email"
                                            v-model="email"
                                            required
                                            autofocus
                                    >

                                    <span
                                            v-if="errors && errors.email"
                                            class="help-block"
                                    >
                                        <strong>
                                            {{ errors.email[0] }}
                                        </strong>
                                    </span>

                                </div>
                            </div>

                            <div :class="{'form-group': true}">

                                <label
                                        for="password"
                                        class="col-md-4 control-label"
                                >
                                    Lozinka
                                </label>

                                <div class="col-md-12">
                                    <input
                                            id="password"
                                            type="password"
                                            class="form-control"
                                            name="password"
                                            v-model="password"
                                            required
                                    >

                                    <span
                                            v-if="errors && errors.password"
                                            class="help-block"
                                    >
                                        <strong>
                                            {{errors.password[0] }}
                                        </strong>
                                    </span>

                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-8 col-md-offset-4">
                                    <button
                                            type="submit"
                                            class="btn btn-primary"
                                            @click.prevent="login"
                                    >
                                        Login
                                    </button>
                                    <a class="btn btn-link" @click.prevent="forgotPassword">
                                        Forgot Your Password?
                                    </a>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";
import Loading from 'vue-loading-overlay'
export default {
    name: "Login",
    components: { Loading },
    data: function() {
        return {
            email: null,
            password: null,
            errors: null,
            open_loader: false,
        }
    },

    methods:{
        ...mapMutations( "auth", ["setUserData"] ),

        login: function(){
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "login", {
                    email: vm.email,
                    password: vm.password
                })
                .then(response => {
                    if( response.data.success ){
                        vm.$store.commit("auth/setUserData", response.data.data.user);
                        localStorage.setItem("access_token", response.data.data.access_token);
                        vm.$router.push('/')
                    } else {
                        vm.errors = response.data.messages
                    }
                    vm.open_loader = false
                })
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                    vm.open_loader = false
                } )
        },

        forgotPassword(){
            this.$router.push('forgot-password')
        }
    },

    mounted(){

    },


}
</script>

<style scoped>

</style>