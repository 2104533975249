<template>
    <div>
        <breadcrumbs
                :title="'Dodatni sadržaji'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Dodatni sadržaj: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <dodatni-sadrzaj-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                >
                </dodatni-sadrzaj-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import DodatniSadrzajForma from "@/views/pages/Postavke/DodatniSadrzaji/DodatniSadrzajForma";
export default {
name: "DodatniSadrzajDetails",
    components: {DodatniSadrzajForma, Breadcrumbs},
    data(){
        return {
            id: this.$route.params.dodatni_sadrzaj_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/postavke/dodatni-sadrzaji',
                    name: 'Dodatni sadržaji'
                },
                {
                    path: ('/postavke/dodatni-sadrzaji/' + this.id ),
                    name: ('Dodatni sadržaj: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>