<template>
    <div>
        <breadcrumbs
                :title="'Računi za uplate'"
                :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Uredi račun: {{ primatelj }}</h5>
            </div>
            <div class="ibox-content">
                <racun-za-ulpatu-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                >
                </racun-za-ulpatu-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import RacunZaUlpatuForma from "@/views/pages/Postavke/RacuniZaUplate/RacunZaUplatuForma";

export default {
    name: "AdministratorEdit",
    components: {RacunZaUlpatuForma,  Breadcrumbs },
    data(){
        return {
            id: this.$route.params.racun_za_uplatu_id,
            breadcrumbs: [],
            primatelj: '',
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/postavke/racuni-za-uplate',
                    name: 'Računi'
                },
                {
                    path: ( '/postavke/racuni-za-uplate/' + this.id ),
                    name: ( 'Račun: ' + this.primatelj )
                },
                {
                    path: ( '/postavke/racuni-za-uplate/' + this.id + '/edit' ),
                    name: ( 'Uredi račun: ' + this.primatelj )
                }
            ]
        },
        setData( data ){
            this.primatelj = data.primatelj ? data.primatelj : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>