<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
        >
        </forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Forms from "@/components/form/Forms";
export default {
    name: "EmailForma",
    components: { Forms, Loading },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/skupovi/' + this.$route.params.skup_id + '/emails',
            url: 'email',
            data: {},
            open_loader: false,
            skup_id: this.$route.params.skup_id,
            skup: null
        }
    },

    methods: {

        init(){
            let vm = this

            axios.get( process.env.VUE_APP_API_URL + 'skup' + '/' + vm.skup_id )
                .then( function ( response ) {
                    vm.skup = response.data

                    if ( vm.action === 'edit' || vm.action === 'show' ){
                        axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                            .then( function ( response ) {
                                vm.data.naslov = response.data.naslov
                                vm.data.sadrzaj = response.data.sadrzaj
                                vm.$emit( 'returnData', response.data )
                                vm.setupForm()
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )

                    } else if ( vm.action === 'create' ) {
                        vm.data = {}
                        vm.setupForm()
                    } else {
                        return
                    }

                } )
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } )
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route //+ ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let skup_id_input = {
                name: 'skup_id',
                type: 'hidden',
                value: vm.skup_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }

            let naslov_input = {
                name: 'naslov',
                type: 'input',
                title: 'Naslov',
                value: vm.data['naslov'] ? vm.data['naslov'] : '',
                width: 12,
                error: vm.errors['naslov'] ? vm.errors['naslov'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }


            let sadrzaj_input = {
                name: 'sadrzaj',
                type: 'editor',
                title: 'Sadržaj',
                value: vm.data.sadrzaj,
                width: 12,
                error: vm.errors['sadrzaj'] ? vm.errors['sadrzaj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }



            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    skup_id_input,
                                    naslov_input,
                                    sadrzaj_input
                                ],
                            },
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }
        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
