<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Prijave</h5>
            </div>
            <div class="ibox-content">
                <poveznica-data-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                        :skup="skup"
                >
                </poveznica-data-forma>
            </div>
        </div>
    </div>

</template>

<script>
import PoveznicaDataForma from "@/views/pages/Skupovi/PovezniceData/PoveznicaDataForma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
name: "PoveznicaDataCreate",
    components: {
        PoveznicaDataForma,
        SkupoviNavigation
    },
    props:[],
    data(){
        return {
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            skup: {}
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/prijava',
                    name: 'Prijave'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/prijave/create',
                    name: 'Nova prijava'
                }
            ]
        },
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>