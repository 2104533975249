<template>
    <div>
        <breadcrumbs
                :title="'Korisnici'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Korisnik {{ ime }} {{ prezime }} | {{ oib }}</h5>
            </div>
            <div class="ibox-content">
                <korisnik-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                >
                </korisnik-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import KorisnikForma from "@/views/pages/Korisnici/KorisnikForma";
export default {
name: "KorisnikDetails",
    components: {KorisnikForma, Breadcrumbs},
    data(){
        return {
            id: this.$route.params.korisnik_id,
            breadcrumbs: [],
            ime: '',
            prezime: '',
            oib: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/korisnici',
                    name: 'Korisnici'
                },
                {
                    path: ( '/korisnici/' + this.id ),
                    name: ( 'Korisnik: ' + this.ime + ' ' + this.prezime + ' | ' + this.oib )
                },
            ]
        },
        setData( data ){
            this.ime = data.ime ? data.ime : ''
            this.prezime = data.prezime ? data.prezime : ''
            this.oib = data.oib ? data.oib : ''
            this.setBreadcrumbs()
        }
    },
}
</script>

<style scoped>

</style>