var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs',{attrs:{"title":'Događaji',"breadcrumbs":_vm.allBreadcrumbs}}),_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light",staticStyle:{"background-color":"#2F4050"}},[_c('a',{class:{
                            'navbar-brand btn btn-light btn-xl': true,
                            'active': _vm.active_route === _vm.route
                        },on:{"click":function($event){return _vm.open(_vm.route)}}},[_c('strong',[_vm._v("Skup")])]),(_vm.skup)?_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[(_vm.skup.vrsta_skupova.prijava_forma && _vm.skup.kotizacija_skupa)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/kotizacije'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open(_vm.route + '/kotizacije')}}},[_c('strong',[_vm._v("Kotizacija")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prijava_forma && !_vm.skup.vrsta_skupova.webinar && _vm.skup.kotizacija_skupa && _vm.komora === 'HKIG')?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/suorganizatori'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open(_vm.route + '/suorganizatori')}}},[_c('strong',[_vm._v("Suorganizatori")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prijava_forma && !_vm.skup.vrsta_skupova.webinar)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/dodatni-sadrzaji'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open(_vm.route + '/dodatni-sadrzaji')}}},[_c('strong',[_vm._v("Dodatni sadržaj")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prijava_forma)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/teme'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open(_vm.route + '/teme')}}},[_c('strong',[_vm._v("Teme")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prijava_forma)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/podteme'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open(_vm.route + '/podteme')}}},[_c('strong',[_vm._v("Podteme")])])]):_vm._e(),(_vm.skup.vrsta_skupova.webinar)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/webinari'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open(_vm.route + '/webinari')}}},[_c('strong',[_vm._v("Webinari")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prijava_forma)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/prijave'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/prijave')}}},[_c('strong',[_vm._v("Prijave")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prilagodjena_forma)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/forma-data'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/forma-data')}}},[_c('strong',[_vm._v("Sudionici")])])]):_vm._e(),(_vm.skup.vrsta_skupova.vanjska_poveznica)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/poveznica-data'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/poveznica-data')}}},[_c('strong',[_vm._v("Sudionici")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prijava_forma)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                        'nav-link btn btn-light' : true,
                                        'active': _vm.active_route === _vm.route + '/ispisi'
                                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/ispisi')}}},[_c('strong',[_vm._v("Ispisi")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prijava_forma && !_vm.skup.vrsta_skupova.webinar && _vm.komora === 'HKIG' )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                            'nav-link btn btn-light' : true,
                                            'active': _vm.active_route === _vm.route + '/dvorane'
                                        },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/dvorane')}}},[_c('strong',[_vm._v("Dvorane")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prijava_forma && !_vm.skup.vrsta_skupova.webinar && _vm.komora === 'HKIG' )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                            'nav-link btn btn-light' : true,
                                            'active': _vm.active_route === _vm.route + '/sudionici'
                                        },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/sudionici')}}},[_c('strong',[_vm._v("Registracija sudionika")])])]):_vm._e(),(_vm.skup.vrsta_skupova.prijava_forma && ( _vm.komora === 'HKIG' || _vm.komora === 'HKIE' ))?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                            'nav-link btn btn-light' : true,
                                            'active': _vm.active_route === _vm.route + '/emails'
                                        },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/emails')}}},[_c('strong',[_vm._v("Obavijesti")])])]):_vm._e()])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }