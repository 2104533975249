<template>
    <div>
        <div v-if="komora==='HKIG'">

            <skupovi-navigation
                    :id="skup_id"
                    :breadcrumbs="breadcrumbs"
            >
            </skupovi-navigation>
            <div class="vld-parent">
                <loading :active.sync="open_loader"
                         :can-cancel="false"
                         :is-full-page="true">
                </loading>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5>Brzi unos sudionika</h5>
                </div>
                <div v-if="form_settings" class="ibox-content">
                    <forms
                            :settings="form_settings"
                            @onFormButtonClick="onFormButtonClick"
                            @onInput="onInput"
                    ></forms>
                </div>
            </div>

            <div class="ibox ">
                <div class="ibox-content">
                    <datatables
                            v-if="show_datatable"
                            :url="datatables_url"
                            :settings="datatables.settings"
                            :title="datatables.title"
                            @buttonClick="datatablesButtonClick"
                    >
                    </datatables>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
import Loading from "vue-loading-overlay";
import Forms from "@/components/form/Forms";
import Datatables from "@/components/datatable/Datatables";
import axios from "axios";
import toastr from "toastr";

export default {
    name: "RegistracijaSudionika",
    components: {
        SkupoviNavigation,
        Forms,
        Loading,
        Datatables
    },
    data(){
        return {

            komora: '',


            // settings for user
            user: null,


            breadcrumbs: [],

            open_loader:false,

            skupovi_select: [],
            skup_id: this.$route.params.skup_id,
            skup: {
                key: null,
                value: null,
            },

            //forms
            form_settings: {},
            form_errors: {},
            form_data: {},

            dvorane_select: [],
            teme_select: [],
            prijave_osobe_select: [],


            //datatables
            datatables: {},
            datatables_url: '',
            show_datatable: false
        }
    },
    methods:{

        async fetchDvorane(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'dvorana', {
                params:{
                    filters: {
                        skup_id: vm.skup_id
                    }
                }
            } )
            return response.data.data.map( function( item ) {
                vm.dvorane_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        getDvorana( id ){
            return this.dvorane_select.find( item => item.key === id )
        },

        async fetchTeme( dvorana_id ){
            let vm = this
            if( dvorana_id ){
                const response = await axios.get(process.env.VUE_APP_API_URL + 'skup-tema', {
                    params:{
                        filters: {
                            dvorana_id: dvorana_id
                        }
                    }
                } )
                return response.data.data.map( function( item ) {
                    vm.teme_select.push(
                        {
                            key: item.id.toString(),
                            value: item.naziv
                        }
                    )
                } )
            }
            else
                vm.teme_select = []
        },

        getTema( id ){
            return this.teme_select.find( item => item.key === id )
        },

        async fetchPrijaveOsobe(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'prijava-osoba', {
                params:{
                    filters: {
                        skup_id: vm.skup_id,
                        ponuda_placena: 1
                    }
                }
            } )
            return response.data.data.map( function( item ) {
                vm.prijave_osobe_select.push(
                    {
                        key: item.id.toString(),
                        value: item.ime + ' ' + item.prezime + ' (' + item.oib + ')'
                    }
                )
            } )
        },

        getPrijavaOsoba( id ){
            return this.prijave_osobe_select.find( item => item.key === id )
        },





        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/sudionici',
                    name: 'Registracija sudionika'
                }
            ]
        },

        async init(){
            let vm = this

            await vm.fetchDvorane()
            await vm.fetchPrijaveOsobe()

            vm.setupForm()


        },

        setupForm(){
            let vm = this;

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + 'skup-sudionik'
                }
            }

            let buttons = []

            buttons.push( button_save )

            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'dvorana_id',
                                        type: 'select',
                                        title: 'Dvorana',
                                        value: vm.getDvorana( vm.form_data.dvorana_id ),
                                        required: true,
                                        width: 12,
                                        error: vm.form_errors['dvorana_id'] ? vm.form_errors['dvorana_id'] : '',
                                        config: {
                                            options: vm.dvorane_select,
                                        }
                                    },
                                    {
                                        name: 'skup_tema_id',
                                        type: 'select',
                                        title: 'Tema',
                                        value: vm.getTema( vm.form_data.skup_tema_id ),
                                        required: true,
                                        width: 12,
                                        error: vm.form_errors['skup_tema_id'] ? vm.form_errors['skup_tema_id'] : '',
                                        config: {
                                            options: vm.teme_select,
                                        }
                                    },
                                    {
                                        name: 'prijava_osoba_id',
                                        type: 'select',
                                        title: 'Osoba',
                                        value: vm.getPrijavaOsoba( vm.form_data.prijava_osoba_id ),
                                        required: true,
                                        width: 12,
                                        error: vm.form_errors['prijava_osoba_id'] ? vm.form_errors['prijava_osoba_id'] : '',
                                        config: {
                                            options: vm.prijave_osobe_select,
                                        }
                                    },
                                ],
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.form_settings = settings
        },


        /**
         *  on input return data
         */
        async onInput( name, value ){
            let vm = this
            if( vm.form_data[name] !== value ){
                vm.open_loader = true
                if( name === 'dvorana_id' )
                    await vm.fetchTeme( value )
                vm.form_data[name] = value
                vm.open_loader = false
                vm.setupForm()

            }
        },


        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            let vm = this
            if( id === 'save' ){
                console.log( data )
                if( data.success){
                    Object.keys( data.messages ).forEach( key => {
                        data.messages[key].forEach( e => {
                            toastr.success( e )
                        })
                    })
                } else {
                    Object.keys( data.messages ).forEach( key => {
                        data.messages[key].forEach( e => {
                            toastr.error( e )
                        })
                    })
                }

                vm.form_data.prijava_osoba_id = ''
                vm.setupForm()
                vm.setDatatables()

            }

        },


        setDatatables(){

            let vm = this;

            vm.datatables = {}
            vm.datatables_url = process.env.VUE_APP_API_URL +  'skup-sudionik'
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter': {
                                value: vm.skup_id
                            }
                        },
                        {
                            'id': 'dvorana_id',
                            'title': 'Dvorane',
                            'type' : function( row ){
                                return row.dvorana_id ? row.dvorane.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'dvorana', {
                                        params: {
                                            filters: {
                                                skup_id: vm.skup_id
                                            }
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'sudionici',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'skup_tema_id',
                            'title': 'Teme',
                            'type' : function( row ){
                                return row.skup_tema_id ? row.skupovi_teme.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'skup-tema', {
                                        params: {
                                            filters: {
                                                skup_id: vm.skup_id
                                            }
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'sudionici',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'ponuda_placena',
                            'title': 'Plačeno',
                            visible: false,
                            'filter': {
                                'value': 1
                            },
                            'column_visibility_group': {
                                id: 'sudionici',
                            }
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'type': function( row ) {
                                return row.prijave_osobe ? row.prijave_osobe.ime : ''
                            },
                            'column_visibility_group': {
                                id: 'sudionici',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'type': function( row ) {
                                return row.prijave_osobe ? row.prijave_osobe.prezime : ''
                            },
                            'column_visibility_group': {
                                id: 'sudionici',
                            }
                        },
                        {
                            'id': 'oib',
                            'title': 'OIB',
                            'type': function( row ) {
                                return row.prijave_osobe ? row.prijave_osobe.oib : ''
                            },
                            'column_visibility_group': {
                                id: 'sudionici',
                            }
                        },
                        {
                            'id': 'datum_i_vrijeme',
                            'title': 'Datun i vrijeme',
                            'searchable': false,
                            'type': function( row ) {
                                return row.datum_i_vrijeme
                            },
                            'column_visibility_group': {
                                id: 'sudionici',
                            }
                        },
                        {
                            'id': 'obrisi_sudionika',
                            'title': 'Obriši sudionika',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'sudionici',
                            },
                            config: {
                                title: 'Obriši',
                                type: 'danger',
                            }
                        }

                    ],
                    column_visibility_groups:{
                        'sudionici' : 'Sudionici'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Sudionici',
            }
            vm.show_datatable = true;

        },

        datatablesButtonClick( col, row  ){
            if ( col.id === 'obrisi_sudionika' ){
                this.deleteSudionik( row.id )
            }
        },

        deleteSudionik( id ){
            let vm = this
            vm.open_loader = true
            axios({
                method: 'DELETE',
                url: process.env.VUE_APP_API_URL + 'skup-sudionik/' + id,
            }).then((response) => {
                vm.open_loader = false
                if( response.data.success ){
                    toastr.success('Zapis je obrisan!')
                    vm.setDatatables()
                } else {
                    toastr.error( "Došli je do greške" )
                }
            }, (error) => {
                vm.open_loader = false
                console.log(error);
            });
        }

    },
    mounted() {
        this.komora = process.env.VUE_APP_APP_OWNER
        this.setBreadcrumbs()
        this.init()
        this.setDatatables()
    }
}
</script>

<style scoped>

</style>
