<template>
    <div>
        <breadcrumbs
                :title="'Dodatni sadržaji'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Novi dodatni sadržaj</h5><br>
            </div>
            <div class="ibox-content">
                <dodatni-sadrzaj-forma
                        :disabled="false"
                        :id="null"
                        :modal = false
                        :action="'create'"
                >
                </dodatni-sadrzaj-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import DodatniSadrzajForma from "@/views/pages/Postavke/DodatniSadrzaji/DodatniSadrzajForma";
export default {
name: "DodatniSadrzajCreate",
    components: {DodatniSadrzajForma, Breadcrumbs},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/dodatni-sadrzaji',
                    name: 'Dodatni sadržaji'
                },
                {
                    path: '/postavke/dodatni-sadrzaji/create',
                    name: 'Novi dodatni sadržaj'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>