<template>
    <div>
        <breadcrumbs
                :title="'Korisnici'"
                :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Korisnici</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";

export default {
name: "Korisnici",
    components: { Breadcrumbs, Datatables},
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'korisnik',
            datatables:{},
            breadcrumbs:[],
        }
    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/korisnici/' + row.id } )
        },
        create(){
            this.$router.push( { path: '/korisnici/create' } )
        },
        setDatatable(){
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'column_visibility_group': {
                                id: 'korisnik',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'column_visibility_group': {
                                id: 'korisnik',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'E-mail',
                            'column_visibility_group': {
                                id: 'korisnik',
                            }
                        },
                        {
                            'id': 'oib',
                            'title': 'Oib',
                            'column_visibility_group': {
                                id: 'korisnik',
                            }
                        },
                        {
                            'id': 'confirmed',
                            'title': 'Potvrđena registracija',
                            'type' : function( row ){
                                return row['confirmed'] ? 'DA' : 'NE'
                            },
                            'filter': {
                                'type': 'select',
                                'search' : [
                                    {
                                        key: '0',
                                        value: 'NE'
                                    },
                                    {
                                        key: '1',
                                        value: 'DA'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'korisnik',
                            }
                        }
                    ],
                    column_visibility_groups:{
                        'korisnik' : 'Korisnik'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Korisnici',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/korisnici',
                    name: 'Korisnici'
                }
            ];
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
