<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="[{
                    name: 'Ispisi',
                    path: ''
                }]"
        >
        </skupovi-navigation>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Ispisi</h5>
                <forms
                        :settings="form_settings"
                        @onInput="onIspisSelect"
                >
                </forms>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
import Loading from "vue-loading-overlay";
import Forms from "@/components/form/Forms";
import Datatables from "@/components/datatable/Datatables";
import axios from "axios";
export default {
    name: "Ispisi",
    components: {
        SkupoviNavigation,
        Forms,
        Loading,
        Datatables
    },
    data(){
        return {
            komora: process.env.VUE_APP_APP_OWNER,
            open_loader:false,

            skup_id: this.$route.params.skup_id,
            //skup: null,

            form_settings: {},

            datatables: {},
            datatables_url: '',
            show_datatable: false
        }
    },
    methods: {
        setup_form(){
            let vm = this

            let options = [];

            if( vm.komora === 'HKIE' ){
                options = [
                    {
                        key: "5",
                        value: "Dodatni sadržaji"
                    }
                ]
            } else if ( vm.komora === 'HKIG' ){
                options = [
                    {
                        key: "1",
                        value: "Sudionici + osobe u pratnji"
                    },
                    {
                        key: "2",
                        value: "Prijave po temama"
                    },
                    {
                        key: "3",
                        value: "Broj prijavljenih po temama"
                    },
                    {
                        key: "4",
                        value: "Uplate kotizacija"
                    },
                    {
                        key: "5",
                        value: "Sudionici + tvrtke"
                    },
                ]
            }

            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'ispis',
                                        type: 'select',
                                        title: 'Ispis',
                                        value: {
                                            key: '',
                                            value: ''
                                        },
                                        error: '',
                                        width: 6,
                                        config: {
                                            options: options,
                                            multiple: false,
                                            title_width: 2,
                                            input_width: 10
                                        }
                                    }

                                ],
                            }
                        ],
                    },
                ],
                buttons: []
            }

            vm.form_settings = settings
        },

        onIspisSelect( name, value ){
            if( value === "1" ){
                this.ispis1()
            } else if( value === "2" ){
                this.ispis2()
            } else if( value === "3" ){
                this.ispis3()
            } else if( value === "4" ){
                this.ispis4()
            } else if ( value === "5" ){
                this.ispis5()
            }
        },

        ispis1(){
            let vm = this;
            vm.datatables = {}
            vm.datatables_url = process.env.VUE_APP_API_URL +  'prijava-osoba'
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter': {
                                value: vm.skup_id
                            }
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'column_visibility_group': {
                                id: 'prijava-osoba',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'column_visibility_group': {
                                id: 'prijava-osoba',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'E-mail',
                            'column_visibility_group': {
                                id: 'prijava-osoba',
                            }
                        },
                        {
                            'id': 'oib',
                            'title': 'OIB',
                            'column_visibility_group': {
                                id: 'prijava-osoba',
                            }
                        },
                        {
                            'id': 'broj_prijave',
                            'type' : function( row ){
                                return row['prijave'] ? row['prijave']['broj_prijave'] : ''
                            },
                            'title': 'Broj prijave',
                            'column_visibility_group': {
                                id: 'prijava-osoba',
                            }
                        },
                        {
                            'id': 'vrsta_kotizacije_id',
                            'title': 'Vrsta',
                            'type' : function( row ){
                                return row['vrste_kotizacija'] ? row['vrste_kotizacija']['naziv'] : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;
                                    axios.get( process.env.VUE_APP_API_URL +  'vrsta-kotizacije')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.naziv
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'prijava-osoba',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'prijava-osoba' : 'Prijave'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Prijavljeni + osobe u pratnji',
            }
            vm.show_datatable = true;
        },


        ispis2(){
            let vm = this
            vm.datatables = {}
            vm.show_datatable = false
            vm.datatables_url = process.env.VUE_APP_API_URL +  'prijava'
            axios.get( process.env.VUE_APP_API_URL + 'skup' + '/' + vm.skup_id )
                    .then( function ( response ) {
                        let skup = response.data
                        let table_header = []

                        table_header.push(
                                {
                                    'id': 'id',
                                    'visible': false,
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'skup_id',
                                    'visible': false,
                                    'filter': {
                                        value: vm.skup_id
                                    }
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'ime',
                                    'title': 'Ime',
                                    'type' : function( row ){
                                        return row['prijave_osobe'] ? row['prijave_osobe'][0]['ime'] : ''
                                    },
                                    'column_visibility_group': {
                                        id: 'prijava-teme',
                                    }
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'prezime',
                                    'title': 'Prezime',
                                    'type' : function( row ){
                                        return row['prijave_osobe'] ? row['prijave_osobe'][0]['prezime'] : ''
                                    },
                                    'column_visibility_group': {
                                        id: 'prijava-teme',
                                    }
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'email',
                                    'title': 'E-mail',
                                    'type' : function( row ){
                                        return row['prijave_osobe'] ? row['prijave_osobe'][0]['email'] : ''
                                    },
                                    'column_visibility_group': {
                                        id: 'prijava-teme',
                                    }
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'oib',
                                    'title': 'OIB',
                                    'type' : function( row ){
                                        return row['prijave_osobe'] ? row['prijave_osobe'][0]['oib'] : ''
                                    },
                                    'column_visibility_group': {
                                        id: 'prijava-osoba',
                                    }
                                }
                        )

                        if( skup.skupovi_teme.length ){
                            skup.skupovi_teme.forEach( function( item ) {
                                table_header.push(
                                        {
                                            'id': item.id,
                                            'title': item.naziv,
                                            'type' : function( row, col  ){
                                                let ret = false;
                                                if(row['prijave_teme'] ){
                                                    row['prijave_teme'].forEach( function( tema ) {
                                                        if( tema['skup_tema_id'] === col.id && !ret ){
                                                            ret = true
                                                        }
                                                    } )
                                                }
                                                return ret ? '+' : '';
                                            },
                                            'searchable' : false,
                                            'orderable' : false,
                                            'column_visibility_group': {
                                                id: 'prijava-osoba',
                                            }
                                        }
                                )
                            } )
                        }

                        vm.datatables = {
                            settings: {
                                table_header: table_header,
                                column_visibility_groups:{
                                    'prijava-teme' : 'Prijave - Teme'
                                },
                                order: 'desc',
                                order_by: 'id',
                            },
                            title: 'Prijave po temama',
                        }
                        vm.show_datatable = true;
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        },

        ispis3(){
            let vm = this;
            vm.datatables = {}
            vm.datatables_url = process.env.VUE_APP_API_URL +  'skup-tema'
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter': {
                                value: vm.skup_id
                            }
                        },
                        {
                            'id': 'broj_teme',
                            'title': 'Broj',
                            'column_visibility_group': {
                                id: 'teme',
                            }
                        },
                        {
                            'id': 'naziv',
                            'title': 'Tema',
                            'column_visibility_group': {
                                id: 'teme',
                            }
                        },
                        {
                            'id': 'broj',
                            'title': 'Broj sudionika',
                            'type' : function( row ){
                                return row.prijave_teme.length
                            },
                            searchable: false,
                            orderable: false,
                            'column_visibility_group': {
                                id: 'teme',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'teme' : 'Teme'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Broj prijavljenih po temama',
            }
            vm.show_datatable = true;
        },

        ispis4(){
            let vm = this
            vm.datatables = {}
            vm.show_datatable = false
            vm.datatables_url = process.env.VUE_APP_API_URL +  'prijava'
            axios.get( process.env.VUE_APP_API_URL + 'skup' + '/' + vm.skup_id )
                    .then( function ( response ) {
                        let skup = response.data
                        let table_header = []

                        table_header.push(
                                {
                                    'id': 'id',
                                    'visible': false,
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'skup_id',
                                    'visible': false,
                                    'filter': {
                                        value: vm.skup_id
                                    }
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'ime',
                                    'title': 'Ime',
                                    'type' : function( row ){
                                        return row['prijave_osobe'] ? row['prijave_osobe'][0]['ime'] : ''
                                    },
                                    'column_visibility_group': {
                                        id: 'kotizacija',
                                    }
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'prezime',
                                    'title': 'Prezime',
                                    'type' : function( row ){
                                        return row['prijave_osobe'] ? row['prijave_osobe'][0]['prezime'] : ''
                                    },
                                    'column_visibility_group': {
                                        id: 'kotizacija',
                                    }
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'email',
                                    'title': 'E-mail',
                                    'type' : function( row ){
                                        return row['prijave_osobe'] ? row['prijave_osobe'][0]['email'] : ''
                                    },
                                    'column_visibility_group': {
                                        id: 'kotizacija',
                                    }
                                }
                        )

                        table_header.push(
                                {
                                    'id': 'oib',
                                    'title': 'OIB',
                                    'type' : function( row ){
                                        return row['prijave_osobe'] ? row['prijave_osobe'][0]['oib'] : ''
                                    },
                                    'column_visibility_group': {
                                        id: 'kotizacija',
                                    }
                                }
                        )

                        if( skup.kotizacija_skupa ){
                            table_header.push(
                                    {
                                        'id': 'ponuda_placena',
                                        'title': 'Plačeno',
                                        'type': function( row ){
                                            return row.prijave_predracuni ? ( row.prijave_predracuni.ponuda_placena === 1 ? 'Da' : 'Ne' ) : 'Ne'
                                        },
                                        'filter': {
                                            'type': 'select',
                                            'search' : [
                                                {
                                                    key: "0",
                                                    value: 'NE'
                                                },
                                                {
                                                    key: "1",
                                                    value: 'DA'
                                                }
                                            ],
                                            'value': null
                                        },
                                        'column_visibility_group': {
                                            id: 'kotizacija',
                                        }
                                    }
                            )

                            table_header.push(
                                    {
                                        'id': 'iznos',
                                        'title': 'Iznos sa PDV-om',
                                        'type': function( row ){
                                            let iznos = 0;
                                            row.prijave_skupovi_kotizacije.forEach( function ( item ) {
                                                iznos += (
                                                        parseFloat( item.kolicina )
                                                        * (
                                                                parseFloat( item.skupovi_kotizacije.cijena )
                                                                + (
                                                                        parseFloat( item.skupovi_kotizacije.cijena )
                                                                        *
                                                                        parseFloat( item.skupovi_kotizacije.porezi.postotak )
                                                                )
                                                        )
                                                )
                                            } )
                                            return iznos.toFixed( 2 )
                                        },
                                        'searchable' : false,
                                        'column_visibility_group': {
                                            id: 'kotizacija',
                                        }
                                    }
                            )

                        }


                        vm.datatables = {
                            settings: {
                                table_header: table_header,
                                column_visibility_groups:{
                                    'kotizacija' : 'Kotizacija'
                                },
                                order: 'desc',
                                order_by: 'id',
                            },
                            title: 'Uplate kotizacija',
                        }
                        vm.show_datatable = true;
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        },

        ispis5(){
            let vm = this;
            vm.datatables = {}
            vm.datatables_url = process.env.VUE_APP_API_URL +  'prijava'
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter': {
                                value: vm.skup_id
                            }
                        },
                        {
                            'id': 'broj_prijave',
                            'title': 'Broj prijave',
                            'column_visibility_group': {
                                id: 'prijava',
                            }
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'type': function( row ){
                                return row.prijave_osobe[0].ime
                            },
                            'column_visibility_group': {
                                id: 'prijava',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'type': function( row ){
                                return row.prijave_osobe[0].prezime
                            },
                            'column_visibility_group': {
                                id: 'prijava',
                            }
                        },
                        {
                            'id': 'oib',
                            'title': 'OIB',
                            'type': function( row ){
                                return row.prijave_osobe[0].oib
                            },
                            'column_visibility_group': {
                                id: 'prijava',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'E-mail',
                            'type': function( row ){
                                return row.prijave_osobe[0].email
                            },
                            'column_visibility_group': {
                                id: 'prijava',
                            }
                        },


                        {
                            'id': 'tvrtka_naziv',
                            'title': 'Tvrtka',
                            'type' : function( row ){
                                return row.prijave_tvrtke ? row.prijave_tvrtke.tvrtka_naziv : ''
                            },
                            'searchable' : true,
                            'column_visibility_group': {
                                id: 'prijava',
                            }
                        },
                        {
                            'id': 'tvrtka_oib',
                            'title': 'Tvrtka',
                            'type' : function( row ){
                                return row.prijave_tvrtke ? row.prijave_tvrtke.tvrtka_oib : ''
                            },
                            'searchable' : true,
                            'column_visibility_group': {
                                id: 'prijava',
                            }
                        }

                    ],
                    column_visibility_groups:{
                        'prijava' : 'Sudionici'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Sudionici + tvrtke',
            }
            vm.show_datatable = true;
        }
    },
    mounted() {
        //breadcrumbs
        this.$emit( 'addBreadcrumbs', [
            {
                path: '/skupovi/' + this.skup_id + '/ispisi',
                name: 'Ispisi'
            },
        ] )

        // select input
        this.setup_form()
    }

}
</script>

<style scoped>

</style>
