<template>
    <div>
        <breadcrumbs
                :title="'Korisnici'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Registrirani korisnici</h5><br>
            </div>
            <div class="ibox-content">
                <korisnik-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                ></korisnik-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import KorisnikForma from "@/views/pages/Korisnici/KorisnikForma";
export default {
name: "KorisnikCreate",
    components: { KorisnikForma, Breadcrumbs},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/korisnici',
                    name: 'Korisnici'
                },
                {
                    path: '/administratori/create',
                    name: 'Novi korisnik'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>