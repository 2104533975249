<template>
    <div class="row">
        <label :class="title_width" :for="name">
            <strong class="float-right">{{title}} {{ required ? '*' : ''}}</strong>
        </label>
        <div v-if="!readonly && !disabled" :class="input_width">
            <span class="form-text m-b-none text-success">
                    {{ note }}
            </span>
            <div class="input-group-append date">

                <v-date-picker
                        locale="hr"
                        icon='calendar'
                        :mode="config.mode ? config.mode : 'date'"
                        v-model="state"
                        :show-day-popover=false
                        :popover="{ visibility: 'click' }"

                        is24hr
                        :masks='{
                            inputDateTime24hr:"DD.MM.YYYY. HH:mm",
                            inputTime24hr:"HH:mm",
                            input:"DD.MM.YYYY.",
                        }'

                        @input="_return"
                        style="width: 100%"
                >
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-group">
                            <input
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    class="form-control"
                            />
                            <span  class="input-group-append">
                                <button
                                        @click.prevent="unset"
                                        type="button"
                                        class="btn btn-sm btn-dark"
                                >x
                                </button>
                            </span>
                            <span
                                    v-if="explanation"
                                    class="input-group-addon"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    :title="explanation"
                            >
                                <i class="fa fa-info" aria-hidden="true"></i>
                            </span>

                        </div>
                    </template>
                </v-date-picker>

            </div>
            <span class="form-text m-b-none text-danger">
                <div class="row">
                    <div class="col-lg-6">
                    {{ error[0] }}
                </div>
                    <!--                <div class="col-md-6">-->
                    <!--                    <button class="btn float-right" style="margin-left: 2px; margin-right: 2px">123</button>-->
                    <!--                </div>-->
                </div>
            </span>
        </div>
        <div v-else :class="input_width">
            <input
                    :name="name"
                    :value="value"
                    :readonly="readonly"
                    :disabled="disabled"
                    class="form-control"
            >
        </div>
    </div>
</template>

<script>

import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from "moment";

export default {
    name: "DatepickerComponent",
    components: {
        'v-date-picker': DatePicker,
    },
    props: [
        'name',
        'value',
        'title',
        'error',
        'note',
        'explanation',
        'placeholder',
        'required',
        'readonly',
        'disabled',
        'config'
    ],
    data(){
        return {
            state: null,
            title_width: ('col-form-label col-lg-' + ( this.config.title_width ? this.config.title_width : '4' ) ),
            input_width: ('col-lg-' + ( this.config.input_width ? this.config.input_width : '8' ) )
        }
    },
    methods: {
        _return(){
            if( this.state )
                this.$emit( 'onInput', this.name, moment(this.state, 'DD.MM.YYYY. HH:mm').format( this.config.mode === 'date' ? 'DD.MM.YYYY.' : 'DD.MM.YYYY. HH:mm' ) )
            else
                this.$emit( 'onInput', this.name, null )

        },
        unset(){
            this.state = null
            this._return()
        }
    },
    mounted() {
        if( this.value )
            this.state = moment( this.value, 'DD.MM.YYYY. HH:mm' ).toDate()
        else
            this.state = null
        this._return()
    },
    watch: {
        value() {
            if( this.value )
                this.state = moment( this.value, 'DD.MM.YYYY. HH:mm' ).toDate()
            else
                this.state = null
            this._return()
        }
    }
}
</script>

<style scoped>
.date-picker input{
    display: block !important;
    width: 100% !important;
}
</style>
