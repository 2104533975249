<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Sudionici</h5><br>
                        <button
                                type="button"
                                class="btn btn-lg btn-primary"
                                @click.prevent="create"
                        >
                            Novi unos
                        </button>
            </div>
            <div class="ibox-content">

                <div class="vld-parent">
                    <loading :active.sync="open_loader"
                             :can-cancel="false"
                             :is-full-page="true">
                    </loading>
                </div>

                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                >
                </datatables>

                <a ref="download_link"></a>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Loading from "vue-loading-overlay";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";

export default {
    name: "Forma28",
    components: {
        Datatables,
        Loading,
        SkupoviNavigation
    },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'forma-data-28',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            open_loader: false
        }

    },
    methods:{

        // rowClick( row ){
        //     this.$router.push( { path: '/skupovi/' + this.skup_id + '/forma_data/' + row.id } )
        // },

        /**
         * za sad se ne može kreirati iz administracije
         */
        create(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/forma-data/create' } )
        },

        setDatatable() {
            let vm = this;

            let id = {
                'id': 'id',
                'visible': false,
            }

            let skup_id = {
                'id': 'skup_id',
                'visible': false,
                'filter':{
                    'type': 'none',
                    'value': vm.skup_id
                },
            }

            let ime = {
                'id': 'ime',
                'title': 'Ime',
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let prezime = {
                'id': 'prezime',
                'title': 'Prezime',
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let oib = {
                'id': 'oib',
                'title': 'OIB',
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let email = {
                'id': 'email',
                'title': 'E-mail',
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let privola1 = {
                'id': 'privola1',
                'title': 'Prebolio',
                'type': function( row ){
                    return row.forma_data ? row.forma_data[0].value : ''
                },
                'searchable': false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let privola2 = {
                'id': 'privola2',
                'title': 'Cijepljen',
                'searchable': false,
                'type': function( row ){
                    return row.forma_data ? row.forma_data[1].value : ''
                },
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let privola3 = {
                'id': 'privola3',
                'title': 'Testiranje',
                'searchable': false,
                'type': function( row ){
                    return row.forma_data ? row.forma_data[2].value : ''
                },
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let privola4 = {
                'id': 'privola4',
                'title': 'Otkazuje',
                'searchable': false,
                'type': function( row ){
                    return row.forma_data ? row.forma_data[3].value : ''
                },
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let table_header = [];

            table_header.push( id )
            table_header.push( skup_id )
            table_header.push( ime )
            table_header.push( prezime )
            table_header.push( oib )
            table_header.push( email )
            table_header.push( privola1 )
            table_header.push( privola2 )
            table_header.push( privola3 )
            table_header.push( privola4 )


            vm.datatables = {
                settings: {
                    table_header: table_header,
                    column_visibility_groups:{
                        'prijava' : 'Prijave'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Sudionici',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/forma-data',
                    name: 'Sudionici'
                }
            ]
        },

    },
    mounted() {
        this.setBreadcrumbs()
        this.setDatatable()
    },
    watch:{
    }
}
</script>

<style scoped>

</style>