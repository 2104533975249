<template>
    <router-view
    ></router-view>
</template>

<script>
export default {
name: "SkupKotizacijaBase",
    methods:{

    }
}
</script>

<style scoped>

</style>