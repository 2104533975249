<template>
    <div>

        <component
                :is="'forma' + skup_id + '-create'"
                :name="skup_id"
        ></component>
    </div>
</template>

<script>

import Forma28Create from "@/views/pages/Skupovi/FormeData/28/Forma28Create";

export default {
    name: "FormaData",
    components: {Forma28Create},
    data(){
        return {
            skup_id: this.$route.params.skup_id,
        }
    }

}
</script>

<style scoped>

</style>