<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Sudionici</h5><br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">

                <div class="vld-parent">
                    <loading :active.sync="open_loader"
                             :can-cancel="false"
                             :is-full-page="true">
                    </loading>
                </div>

                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                >
                </datatables>

                <a ref="download_link"></a>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Loading from "vue-loading-overlay";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";

export default {
    name: "Forma31",
    components: {
        Datatables,
        Loading,
        SkupoviNavigation
    },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'forma-data-31',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            open_loader: false
        }

    },
    methods:{

        // rowClick( row ){
        //     this.$router.push( { path: '/skupovi/' + this.skup_id + '/forma_data/' + row.id } )
        // },

        /**
         * za sad se ne može kreirati iz administracije
         */
        // create(){
        //     this.$router.push( { path: '/skupovi/' + this.skup_id + '/forma-data/create' } )
        // },

        setDatatable() {
            let vm = this;

            let id = {
                'id': 'id',
                'visible': false,
            }


            let ime = {
                'id': 'ime',
                'title': 'Ime',
                'type' : function ( row ){
                    if( row.forma_data ){
                        return row.forma_data[0].value
                    }
                },
                'searchable' : false,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let prezime = {
                'id': 'prezime',
                'title': 'Prezime',
                'type' : function ( row ){
                    if( row.forma_data ){
                        return row.forma_data[1].value
                    }
                },
                'searchable' : false,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let oib = {
                'id': 'oib',
                'title': 'OIB',
                'type' : function ( row ){
                    if( row.forma_data ){
                        return row.forma_data[2].value
                    }
                },
                'searchable' : false,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }



            let adresa = {
                'id': 'adresa',
                'title': 'Adresa',
                'type' : function ( row ){
                    if( row.forma_data ){
                        return row.forma_data[3].value
                    }
                },
                'searchable' : false,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let mjesto = {
                'id': 'mjesto',
                'title': 'Mjesto',
                'type' : function ( row ){
                    if( row.forma_data ){
                        return row.forma_data[4].value
                    }
                },
                'searchable' : false,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let postanski_broj = {
                'id': 'postanski_broj',
                'title': 'Poštanski broj',
                'type' : function ( row ){
                    if( row.forma_data ){
                        return row.forma_data[5].value
                    }
                },
                'searchable' : false,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let zupanija = {
                'id': 'zupanija',
                'title': 'Županija',
                'type' : function ( row ){
                    if( row.forma_data ){
                        return row.forma_data[6].value
                    }
                },
                'searchable' : false,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let izborna_jedinica = {
                'id': 'izborna_jedinica',
                'title': 'Izborna jedinica',
                'type' : function ( row ){
                    if( row.forma_data ){
                        return row.forma_data[7].value
                    }
                },
                'searchable' : false,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let email = {
                'id': 'email',
                'title': 'E-mail',
                'type' : function ( row ){
                    if( row.forma_data ){
                        return row.forma_data[8].value
                    }
                },
                'searchable' : false,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }



            let table_header = [];

            table_header.push( id )
            table_header.push( ime )
            table_header.push( prezime )
            table_header.push( oib )
            table_header.push( email )
            table_header.push( adresa )
            table_header.push( mjesto )
            table_header.push( postanski_broj )
            table_header.push( zupanija )
            table_header.push( izborna_jedinica )




            vm.datatables = {
                settings: {
                    table_header: table_header,
                    column_visibility_groups:{
                        'prijava' : 'Prijave'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Sudionici',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/forma-data',
                    name: 'Sudionici'
                }
            ]
        },

    },
    mounted() {
        this.setBreadcrumbs()
        this.setDatatable()
    },
    watch:{
    }
}
</script>

<style scoped>

</style>