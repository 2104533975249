<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: "PrijavaBase",
    methods:{
    }
}
</script>

<style scoped>

</style>
