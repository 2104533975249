<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Sudionici</h5><br>
            </div>
            <div class="ibox-content">


                <div class="row">
                    <div class="col-md-12">
                        <forms
                            :settings="settings"
                            @onFormButtonClick="onFormButtonClick"
                        ></forms>
                    </div>
                </div>

                <br><br><br>

                <div class="vld-parent">
                    <loading :active.sync="open_loader"
                             :can-cancel="false"
                             :is-full-page="true">
                    </loading>
                </div>

                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                >
                </datatables>

                <a ref="download_link"></a>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Loading from "vue-loading-overlay";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
import Forms from "../../../../../components/form/Forms";
import axios from "axios";

export default {
    name: "Forma49",
    components: {
        Forms,
        Datatables,
        Loading,
        SkupoviNavigation
    },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'hkig/forma-data-49',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            open_loader: false,

            // forma za ispis
            settings:{}
        }

    },
    methods:{

        setDatatable() {
            let vm = this;

            let id = {
                'id': 'id',
                'visible': false,
            }

            let broj_prijave = {
                'id': 'broj_prijave',
                'title': 'Broj prijave',
                'type' : function ( row ){
                    return row.broj_prijave
                },
                'searchable' : true,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let ime = {
                'id': 'ime',
                'title': 'Ime',
                'type' : function ( row ){
                    return row.ime
                },
                'searchable' : true,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let prezime = {
                'id': 'prezime',
                'title': 'Prezime',
                'type' : function ( row ){
                   return row.prezime
                },
                'searchable' : true,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let oib = {
                'id': 'oib',
                'title': 'OIB',
                'type' : function ( row ){
                    return row.oib
                },
                'searchable' : true,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }



            let email = {
                'id': 'email',
                'title': 'E-mail',
                'type' : function ( row ){
                    return row.email
                },
                'searchable' : true,
                'orderable' : false,
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let created_at = {
                'id': 'created_at',
                'title': 'Datum i vrijeme prijave',
                'type' : function ( row ){
                    if( row ){
                        return row.created_at
                    }
                },
                'searchable' : true,
                'orderable' : true,
                'column_visibility_group': {
                    id: 'prijava',
                },
                'filter': {
                    'type': 'date',
                }
            }


            let table_header = [];

            table_header.push( id )
            table_header.push( broj_prijave )
            table_header.push( ime )
            table_header.push( prezime )
            table_header.push( oib )
            table_header.push( email )
            table_header.push( created_at )




            vm.datatables = {
                settings: {
                    table_header: table_header,
                    column_visibility_groups:{
                        'prijava' : 'Prijave'
                    },
                    order: 'asc',
                    order_by: 'broj_prijave',
                },
                title: 'Sudionici',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/forma-data',
                    name: 'Sudionici'
                }
            ]
        },



        setupForm(){

            let vm = this;

            vm.buttons = []


            let button_download = {
                id: 'download',
                title: 'Preuzmi',
                type: 'primary',
            }

            let buttons = []

            buttons.push( button_download )

            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: 'Ispis',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'datum_od',
                                        type: 'datepicker',
                                        title: 'Od datuma',
                                        value: null,
                                        width: 6,
                                        error: '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            mode:'date', // date // time
                                        }
                                    },
                                    {
                                        name: 'tip',
                                        type: 'select',
                                        title: '',
                                        value: null,
                                        width: 12,
                                        error: '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: false,
                                        readonly: false,
                                        disabled: false,
                                        config: {
                                            options: function ( ) {
                                                let model = this

                                                if( vm.action === 'show'){
                                                    model.options = []
                                                    return
                                                }

                                                model.options = [
                                                    {
                                                        key: '1',
                                                        value: 'Novi ( Članovi koji su prvi puta nakon odabranog datuma )'
                                                    },
                                                    {
                                                        key: '2',
                                                        value: 'Deaktivacija ( Članovi koji su promijenili status u NEAKTIVAN nakon odabranog datuma )'
                                                    },
                                                    {
                                                        key: '3',
                                                        value: 'Reaktivacija ( Članovi koji su promijenili status u AKTIVAN nakon odabranog datuma )'
                                                    },
                                                ]
                                            },
                                            multiple: false,
                                            title_width: 2,
                                            input_width: 10,
                                        }
                                    }
                                ],
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        onFormButtonClick( id, action, config, input_data  ){
            let vm = this
            vm.open_loader = true
            if( id === 'download' ){
                axios.get( vm.datatables_url, {
                    params: {
                        'page': '1',
                        'per_page': '-1',
                        'order_by': 'created_at',
                        'order': 'desc',
                        'filters': {
                            'created_at': {
                                from: input_data.datum_od
                            },
                            'tip': input_data.tip
                        },
                        'options': {}
                    }
                } )
                    .then( response => {

                        let title = 'tablica'

                        if( input_data.tip == 1 ){
                            title = 'Novi'
                        } else if ( input_data.tip == 2 ){
                            title = 'Deaktivacija'
                        } else if ( input_data.tip == 3 ){
                            title = 'Reaktivacija'
                        }

                        let data = response.data.data

                        let export_data = []

                        Object.keys( data ).forEach( key => {
                            export_data.push( [
                                data[key].broj_prijave,
                                data[key].oib,
                                data[key].ime,
                                data[key].prezime,
                                data[key].email
                            ] )
                        } )


                        /* generate worksheet */
                        var ws = window.XLSX.utils.aoa_to_sheet(export_data)


                        /* generate workbook */
                        var wb = window.XLSX.utils.book_new()
                        window.XLSX.utils.book_append_sheet(wb, ws, "sheet1")

                        /* generate file and download */
                        const wbout_c = window.XLSX.write(wb, { type: "array", bookType: "xlsx" })
                        let blob = new Blob([wbout_c], { type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," })
                        var a = document.createElement("a")
                        a.href = URL.createObjectURL(blob)
                        a.download = title + '.xlsx'
                        a.click()

                        vm.open_loader = false;
                    })
                    .catch( error => {
                        console.log( error )
                        vm.open_loader = false;
                    } )
            }
        },

    },
    mounted() {
        this.setBreadcrumbs()
        this.setDatatable()
        this.setupForm()
    },
    watch:{
    }
}
</script>

<style scoped>

</style>
