<template>
    <div>
        <breadcrumbs
                :title="'Računi za uplate'"
                :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Novi račun</h5>
            </div>
            <div class="ibox-content">
                <racun-za-ulpatu-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                >
                </racun-za-ulpatu-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import RacunZaUlpatuForma from "@/views/pages/Postavke/RacuniZaUplate/RacunZaUplatuForma";

export default {
    name: "AdministratorCreate",
    components: {RacunZaUlpatuForma, Breadcrumbs },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/postavke/racuni-za-uplate',
                    name: 'Računi za uplate'
                },
                {
                    path: '/postavke/racuni-za-uplate/create',
                    name: 'Novi račun'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>