<template>

    <div>
       <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Prijave</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">

                <div class="vld-parent">
                    <loading :active.sync="open_loader"
                             :can-cancel="false"
                             :is-full-page="true">
                    </loading>
                </div>

                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                        @buttonClick="buttonClick"
                >
                </datatables>

                <a ref="download_link"></a>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Loading from "vue-loading-overlay";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
import axios from "axios";
import toastr from "toastr";

export default {
    name: "Prijave",
    components: {
        Datatables,
        Loading,
        SkupoviNavigation
    },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'prijava',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            open_loader: false,
        }

    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/prijave/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/prijave/create' } )
        },

        setDatatable() {
            let vm = this;

            let id = {
                'id': 'id',
                'visible': false,
            }

            let skup_id = {
                'id': 'skup_id',
                'visible': false,
                'filter':{
                    'type': 'none',
                    'value': vm.skup_id
                },
            }

            let broj_prijave = {
                'id': 'broj_prijave',
                'title': 'Broj prijave',
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let ime = {
                'id': 'ime',
                'title': 'Ime',
                'type': function( row ){
                    return row.prijave_osobe[0].ime
                },
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let prezime = {
                'id': 'prezime',
                'title': 'Prezime',
                'type': function( row ){
                    return row.prijave_osobe[0].prezime
                },
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let oib = {
                'id': 'oib',
                'title': 'OIB',
                'type': function( row ){
                    return row.prijave_osobe[0].oib
                },
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let email = {
                'id': 'email',
                'title': 'E-mail',
                'type': function( row ){
                    return row.prijave_osobe[0].email
                },
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let broj_osoba_u_pratnji = {
                'id': 'broj_osoba_u_pratnji',
                'title': 'Broj osoba u pratnji',
                'type': function( row ){
                    return row.prijave_osobe.length-1
                },
                'column_visibility_group': {
                    id: 'prijava',
                },
                'filter': {
                    'type': 'none',
                    'search': '',
                    'value': null,
                },
            }

            let ponuda_placena = {
                'id': 'ponuda_placena',
                'title': 'Plačeno',
                'type': function( row ){
                    return row.prijave_predracuni && row.prijave_predracuni.ponuda_placena === 1 ? 'Da' : 'Ne'
                },
                'filter': {
                    'type': 'select',
                    'search' : [
                        {
                            key: "0",
                            value: 'NE'
                        },
                        {
                            key: "1",
                            value: 'DA'
                        }
                    ],
                    'value': null
                },
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let otkazano = {
                'id': 'otkazano',
                'title': 'Otkazano',
                'type': function( row ){
                    return row.otkazano ? 'Da' : 'Ne'
                },
                'filter': {
                    'type': 'select',
                    'search' : [
                        {
                            key: "0",
                            value: 'NE'
                        },
                        {
                            key: "1",
                            value: 'DA'
                        }
                    ],
                    'value': null
                },
                'column_visibility_group': {
                    id: 'prijava',
                }
            }

            let posalji_ponudu = {
                'id': 'posalji_ponudu',
                'title': 'Pošalji ponudu',
                'searchable': false,
                'type': 'button',
                'filter': {
                    'type': 'none',
                    'search': '',
                    'value': null,
                },
                'column_visibility_group': {
                    id: 'prijava',
                },
                config: {
                    title: 'Pošalji',
                    type: 'success',
                }
            }

            let ispisi_ponudu = {
                'id': 'ispisi_ponudu',
                'title': 'Ispiši ponudu',
                'searchable': false,
                'type': 'button',
                'filter': {
                    'type': 'none',
                    'search': '',
                    'value': null,
                },
                'column_visibility_group': {
                    id: 'prijava',
                },
                config: {
                    title: 'Ispiši',
                    type: 'primary',
                }
            }


            let preuzmi_qr = {
                'id': 'preuzmi_qr',
                'title': 'QR kod',
                'searchable': false,
                'type': 'button',
                'filter': {
                    'type': 'none',
                    'search': '',
                    'value': null,
                },
                'column_visibility_group': {
                    id: 'prijava',
                },
                config: {
                    title: 'Preuzmi',
                    type: 'warning',
                }
            }


            let suorganizator = {
                'id': 'skup_suorganizator_id',
                'title': 'Suorganizator',
                'type': function( row ){
                    return row.prijave_osobe[0].skupovi_suorganizatori ? row.prijave_osobe[0].skupovi_suorganizatori.naziv : ''
                },
                'filter': {
                    'type': 'select',
                    'search': function ( ){
                        let component = this;

                        axios.get( process.env.VUE_APP_API_URL +  'skup-suorganizator', {
                            params: {
                                filters: {
                                    skup_id: vm.skup_id
                                }
                            }
                        } )
                            .then( function ( response ) {
                                let tmp = [];

                                response.data.data.forEach( function ( item ) {
                                    tmp.push({
                                        key: item.id,
                                        value: item.naziv
                                    } );
                                } );
                                component.$data.tmp = tmp;
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                    },
                },
                        'column_visibility_group': {
                    id: 'prijava',
                }
            }


            let table_header = [];

            table_header.push( broj_prijave)
            table_header.push( id )
            table_header.push( skup_id )
            table_header.push( ime )
            table_header.push( prezime )
            table_header.push( oib )
            table_header.push( email )
            if( vm.skup.broj_osoba_u_pratnji )
                table_header.push( broj_osoba_u_pratnji )
            if( vm.skup.kotizacija_skupa  ? vm.skup.kotizacija_skupa : null ) {
                table_header.push( posalji_ponudu )
                table_header.push( ispisi_ponudu )
                if( process.env.VUE_APP_APP_OWNER === 'HKIG' )
                    table_header.push( preuzmi_qr )
                table_header.push( ponuda_placena )
                table_header.push( suorganizator )
            }
            table_header.push( otkazano )

            vm.datatables = {
                settings: {
                    table_header: table_header,
                    column_visibility_groups:{
                        'prijava' : 'Prijave'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Prijave',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/prijave',
                    name: 'Prijave'
                }
            ]
        },

        buttonClick( col, row ){
            let vm = this;

            if ( col.id === 'posalji_ponudu'){
                vm.open_loader = true
                axios({
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + 'ponuda-template',
                    data: {
                        prijava_id : row.id
                    }
                }).then((response) => {
                    axios( {
                        method: 'GET',
                        url: process.env.VUE_APP_API_URL + 'ponuda-template/mail/' + response.data.data.id
                    } ).then( ( response )=> {
                        if( response.data.success ){
                            toastr.success( 'Uspješno poslano!')
                        } else {
                            toastr.error( 'Došlo je do greške kod slanja!' )
                        }
                        vm.open_loader = false
                    }, ( error )=> {
                        console.log(error);
                        toastr.error( 'Došlo je do greške kod slanja!' )
                        vm.open_loader = false
                    })
                }, (error) => {
                    vm.open_loader = false
                    console.log(error);
                })
            } else if ( col.id === 'ispisi_ponudu') {
                vm.open_loader = true
                axios({
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + 'ponuda-template',
                    data: {
                        prijava_id : row.id,
                    }
                }).then((response) => {
                    let template_id = response.data.data.id
                    axios( {
                        method: 'GET',
                        responseType: 'blob',
                        url: process.env.VUE_APP_API_URL + 'ponuda-template/download/' + template_id
                    }).then( ( response )=> {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', template_id + '.pdf')
                        document.body.appendChild(link)
                        link.click()
                        vm.open_loader = false
                    }, ( error )=> {
                        console.log(error);
                        toastr.error( 'Došlo je do greške kod slanja!' )
                        vm.open_loader = false
                    })
                }, (error) => {
                    vm.open_loader = false
                    console.log(error);
                });
            } else if ( col.id === 'preuzmi_qr') {
                vm.open_loader = true;
                axios( {
                    method: 'GET',
                    responseType: 'blob',
                    url: process.env.VUE_APP_API_URL + 'prijava/download-qr/' + row.id
                }).then( ( response )=> {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', row.id + '.pdf')
                    document.body.appendChild(link)
                    link.click()
                    vm.open_loader = false
                }, ( error )=> {
                    console.log(error);
                    toastr.error( 'Došlo je do greške!' )
                    vm.open_loader = false
                })
            }
            //vm.open_loader = false;
        }
    },
    mounted() {
        this.setBreadcrumbs()
        let vm = this
        axios.get( process.env.VUE_APP_API_URL + 'skup' + '/' + vm.skup_id )
                .then( function ( response ) {
                    vm.skup = response.data
                    vm.setDatatable()
                    //setInterval(() => vm.setDatatable(), 100000);
                } )
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } )
    },
}
</script>

<style scoped>

</style>
