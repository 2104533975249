<template>
    <router-view
    ></router-view>
</template>

<script>
export default {
  name: "FormaDataBase",
    methods:{

    }
}
</script>

<style scoped>

</style>