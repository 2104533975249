import { render, staticRenderFns } from "./RacunZaUplatuDetails.vue?vue&type=template&id=089f6bf0&scoped=true&"
import script from "./RacunZaUplatuDetails.vue?vue&type=script&lang=js&"
export * from "./RacunZaUplatuDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "089f6bf0",
  null
  
)

export default component.exports