<template>
    <router-view
    ></router-view>
</template>

<script>
export default {
name: "SkupTema2Base",
    methods:{
    }
}
</script>

<style scoped>

</style>
