<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>

        <div class="ibox ">
            <div class="ibox-title">
                <h5>Dvorane</h5>
            </div>
            <div class="ibox-content">
                <dvorana-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                >
                </dvorana-forma>
            </div>
        </div>
    </div>
</template>

<script>
import DvoranaForma from "@/views/pages/Skupovi/Dvorane/DvoranaForma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
    name: "DvoranaCreate",
    components:{
        DvoranaForma,
        SkupoviNavigation
    },
    data(){
        return {
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/dvorane',
                    name: 'Dvorane'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/dvorane/create',
                    name: 'Nova dvorana'
                }
            ]
        },

    },
    mounted() {
        this.setBreadcrumbs()
    }

}
</script>

<style scoped>

</style>