import { render, staticRenderFns } from "./SkupTemaForma.vue?vue&type=template&id=ec05de7a&scoped=true&"
import script from "./SkupTemaForma.vue?vue&type=script&lang=js&"
export * from "./SkupTemaForma.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec05de7a",
  null
  
)

export default component.exports