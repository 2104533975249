<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Webinar: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <webinar-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                >
                </webinar-forma>
            </div>
        </div>
    </div>
</template>

<script>
import WebinarForma from "@/views/pages/Skupovi/Webinars/WebinarForma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
name: "WebinarDetails",
    components: {
        WebinarForma,
        SkupoviNavigation
    },
    data(){
        return {
            breadcrumbs: [],
            id:this.$route.params.webinar_id,
            skup_id: this.$route.params.skup_id,
            skup: {},
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/webinari',
                    name: 'Webinari'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/webinari/' + this.id,
                    name: 'Webinar: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv
            this.setBreadcrumbs()
        },

    },
    mounted() {
    }
}
</script>

<style scoped>

</style>