<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>

        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        >
        </forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Loading from "vue-loading-overlay";
import Forms from "@/components/form/Forms";
export default {
    name: "FormaDataForma",
    components: { Forms, Loading },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        skup: {
            defaulf: {}
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/skupovi/' + this.$route.params.skup_id + '/prijave',
            url: 'prijava',
            data: {},
            skup_id: this.$route.params.skup_id,
            open_loader: false,

            /**
             * tmp data
             */
            korisnik: {},
            izzyedu_osoba: {},
            tvrtka:{},

        }
    },

    methods: {

        init(){
            let vm = this
            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {
                            vm.data = response.data
                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs0 = [];
            let inputs1 = [];
            let inputs2 = [];
            let inputs3 = [];
            let inputs4 = [];
            let inputs5 = [];
            let inputs6 = [];
            let inputs7 = [];

            let sections = [];

            /**
             * SKUP
             */

            let skup_id_input = {
                name: 'skup_id',
                type: 'hidden',
                value: vm.skup_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            };

            let naziv_skupa = vm.skup.opis
            let naziv_skupa_input = {
                name: 'naziv skupa',
                type: 'editor',
                title: '',
                value: naziv_skupa,
                width: 12,
                error: vm.errors['naziv_skupa'] ? vm.errors['naziv_skupa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: true,
                disabled: true,
                config: {
                    title_width: 0,
                    input_width: 12
                }
            }

            inputs0.push( naziv_skupa_input )
            inputs0.push( skup_id_input )
            sections.push( {
                name: 'Događaj',
                width: 12,
                inputs: inputs0,
            } )


            /**
             * OSOBA
             */
            let korisnik_id_input_value = {
                key: '',
                value: ''
            }
            if( vm.action === 'show' ) {
                korisnik_id_input_value = {
                    key: '',
                    value: ''
                }
            } else {
                korisnik_id_input_value = {
                    key: vm.korisnik.id ? vm.korisnik.id : '',
                    value: vm.izzyedu_osoba.id
                            ? ( vm.izzyedu_osoba.ime + ' ' + vm.izzyedu_osoba.prezime + ' | ' + vm.izzyedu_osoba.oib )
                            : ( vm.korisnik.id ? vm.korisnik.ime + ' ' + vm.korisnik.prezime + ' | ' + vm.korisnik.oib : '' )
                }
            }
            let korisnik_id_input = {
                name: 'korisnik_id',
                type: 'select',
                title: 'Korisnik',
                value: korisnik_id_input_value,
                width: 12,
                error: vm.errors['korisnik_id'] ? vm.errors['korisnik_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }
                        axios.get( process.env.VUE_APP_API_URL +  'korisnik', {
                            params:{
                                order: 'asc',
                                order_by: 'prezime'
                            }
                        } )
                            .then( function ( response ) {
                                let tmp = [];
                                response.data.data.forEach( function ( item ) {
                                    tmp.push({
                                        key: item.id,
                                        value: ( item.prezime + ' ' + item.ime + ' | ' + item.oib )
                                    } );
                                } );
                                model.options = tmp
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                    },
                    multiple: false,
                    title_width: 1,
                    input_width: 11
                }
            }

            let ime = ''
            if( vm.action === 'show' )
                ime = vm.data.prijave_osobe[0].ime
            else {
                ime = vm.izzyedu_osoba.ime ? vm.izzyedu_osoba.ime : ( vm.korisnik.ime ? vm.korisnik.ime : '' )
            }
            let ime_input = {
                name: 'ime',
                type: 'input',
                title: 'Ime',
                value: ime,
                width: 4,
                error: vm.errors['ime'] ? vm.errors['ime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let prezime = ''
            if( vm.action === 'show' )
                prezime = vm.data.prijave_osobe[0].prezime
            else {
                prezime = vm.izzyedu_osoba.prezime ? vm.izzyedu_osoba.prezime : ( vm.korisnik.prezime ? vm.korisnik.prezime : '' )
            }
            let prezime_input = {
                name: 'prezime',
                type: 'input',
                title: 'Prezime',
                value: prezime,
                width: 4,
                error: vm.errors['preime'] ? vm.errors['preime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let oib = ''
            if( vm.action === 'show' )
                oib = vm.data.prijave_osobe[0].oib
            else {
                oib = vm.izzyedu_osoba.oib ? vm.izzyedu_osoba.oib : ( vm.korisnik.oib ? vm.korisnik.oib : '' )
            }
            let oib_input = {
                name: 'oib',
                type: 'input',
                title: 'OIB',
                value: oib,
                width: 4,
                error: vm.errors['oib'] ? vm.errors['oib'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let adresa = ''
            if( vm.action === 'show' )
                adresa = vm.data.prijave_osobe[0].adresa
            else {
                adresa = vm.izzyedu_osoba.adresa ? vm.izzyedu_osoba.adresa : ''
            }
            let adresa_input = {
                name: 'adresa',
                type: 'input',
                title: 'Adresa',
                value: adresa,
                width: 4,
                error: vm.errors['adresa'] ? vm.errors['adresa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let mjesto = ''
            if( vm.action === 'show' )
                mjesto = vm.data.prijave_osobe[0].mjesto
            else {
                mjesto = vm.izzyedu_osoba.posta ? vm.izzyedu_osoba.posta.mjesto : ''
            }
            let mjesto_input = {
                name: 'mjesto',
                type: 'input',
                title: 'Mjesto',
                value: mjesto,
                width: 4,
                error: vm.errors['mjesto'] ? vm.errors['mjesto'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let postanski_broj = ''
            if( vm.action === 'show' )
                postanski_broj =vm.data.prijave_osobe[0].postanski_broj
            else {
                postanski_broj = vm.izzyedu_osoba.posta ? vm.izzyedu_osoba.posta.postanski_broj : ''
            }
            let postanski_broj_input = {
                name: 'postanski_broj',
                type: 'input',
                title: 'Poštanski broj',
                value: postanski_broj,
                width: 4,
                error: vm.errors['postanski_broj'] ? vm.errors['postanski_broj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let telefon = ''
            if( vm.action === 'show' )
                telefon = vm.data.prijave_osobe[0].telefon
            else {
                telefon = vm.izzyedu_osoba.telefon ? vm.izzyedu_osoba.telefon : ''
            }
            let telefon_input = {
                name: 'telefon_broj',
                type: 'input',
                title: 'Telefon',
                value: telefon,
                width: 4,
                error: vm.errors['telefon'] ? vm.errors['telefon'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let email = ''
            if( vm.action === 'show' )
                email = vm.data.prijave_osobe[0].email
            else {
                email = vm.izzyedu_osoba.email ? vm.izzyedu_osoba.email : ''
            }
            let email_input = {
                name: 'email',
                type: 'input',
                title: 'Email',
                value: email,
                width: 4,
                error: vm.errors['email_broj'] ? vm.errors['email_broj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let mobitel = ''
            if( vm.action === 'show' )
                mobitel = vm.data.prijave_osobe[0].mobitel
            else {
                mobitel = vm.izzyedu_osoba.mobitel ? vm.izzyedu_osoba.mobitel : ''
            }
            let mobitel_input = {
                name: 'mobitel',
                type: 'input',
                title: 'Mobitel',
                value: mobitel,
                width: 4,
                error: vm.errors['mobitel'] ? vm.errors['mobitel'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }



            let spol = {}
            if( vm.action === 'show' )
                spol = vm.data.prijava_osoba.spol
            else
                spol = {
                    key: vm.izzyedu_osoba.spol ? ( ['M', 'Ž'].includes( vm.izzyedu_osoba.spol ) ? ( vm.izzyedu_osoba.spol === 'M' ? 1 : 0 ) : '' ) : '',
                    value: vm.izzyedu_osoba.spol ? ( ['M', 'Ž'].includes( vm.izzyedu_osoba.spol ) ? vm.izzyedu_osoba.spol : '' ) : ''
                }
            let spol_input = {
                name: 'spol',
                type: 'select',
                title: 'Spol',
                value: spol,
                width: 4,
                error: vm.errors['spol'] ? vm.errors['spol'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: [
                        {
                            key: 1,
                            value: 'M'
                        },
                        {
                            key: 0,
                            value: 'Ž'
                        }
                    ],
                    multiple: false,
                    title_width: 3,
                    input_width: 9
                }
            }

            let akademski_stupanj = ''
            if( vm.action === 'show' )
                akademski_stupanj = vm.data.prijave_osobe[0].akademski_stupanj
            else {
                akademski_stupanj = vm.izzyedu_osoba.akademski_stupanj ? vm.izzyedu_osoba.akademski_stupanj.kratki_naziv : ''
            }
            let akademski_stupanj_input = {
                name: 'akademski_stupanj',
                type: 'input',
                title: 'Akademska titula',
                value: akademski_stupanj,
                width: 4,
                error: vm.errors['akademski_stupanj'] ? vm.errors['akademski_stupanj'] : '',
                note: 'mr.sc., dr.sc. i sl.',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let strucni_stupanj = ''
            if( vm.action === 'show' )
                strucni_stupanj = vm.data.prijave_osobe[0].strucni_stupanj
            else {
                strucni_stupanj = vm.izzyedu_osoba.strucni_stupanj ? vm.izzyedu_osoba.strucni_stupanj.kratki_naziv : ''
            }
            let strucni_stupanj_input = {
                name: 'strucni_stupanj',
                type: 'input',
                title: 'Stručna titula',
                value: strucni_stupanj,
                width: 4,
                error: vm.errors['strucni_stupanj'] ? vm.errors['strucni_stupanj'] : '',
                note: 'ing.građ., struč.spec.ing.aedif., dipl.ing.građ., mag.ing.aedif. i sl.',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            inputs1.push( korisnik_id_input )
            inputs1.push( oib_input )
            inputs1.push( ime_input )
            inputs1.push( prezime_input )
            inputs1.push( adresa_input )
            inputs1.push( mjesto_input )
            inputs1.push( postanski_broj_input )
            inputs1.push( telefon_input )
            inputs1.push( email_input )
            inputs1.push( mobitel_input )
            inputs1.push( spol_input )
            inputs1.push( strucni_stupanj_input )
            inputs1.push( akademski_stupanj_input )

            sections.push(
                    {
                        name: 'Osobni podatci',
                        width: 12,
                        inputs: inputs1,
                    }
            )


            /**
             * TVRTKA
             */

            if( vm.skup.tvrtka ){
                let tvrtka_oib = ''
                if( vm.action === 'show' )
                    tvrtka_oib = vm.data.prijava_tvrtke.oib
                let tvrtka_oib_input = {
                    name: 'tvrtka_oib',
                    type: 'input',
                    title: 'OIB tvrtke',
                    value: tvrtka_oib,
                    width: 4,
                    error: vm.errors['tvrtka_oib'] ? vm.errors['tvrtka_oib'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }

                let tvrtka_naziv = ''
                if( vm.action === 'show' )
                    tvrtka_naziv = vm.data.prijava_tvrtke.naziv
                let tvrtka_naziv_input = {
                    name: 'tvrtka_naziv',
                    type: 'input',
                    title: 'Nrazv tvrtke',
                    value: tvrtka_naziv,
                    width: 6,
                    error: vm.errors['tvrtka_naziv'] ? vm.errors['tvrtka_naziv'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 2,
                        input_width: 10
                    }
                }

                let tvrtka_adresa = ''
                if( vm.action === 'show' )
                    tvrtka_adresa = vm.data.prijava_tvrtke.adresa
                let tvrtka_adresa_input = {
                    name: 'tvrtka_adresa',
                    type: 'input',
                    title: 'Adresa',
                    value: tvrtka_adresa,
                    width: 4,
                    error: vm.errors['tvrtka_adresa'] ? vm.errors['tvrtka_adresa'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }

                let tvrtka_mjesto = ''
                if( vm.action === 'show' )
                    tvrtka_mjesto = vm.data.prijava_tvrtke.mjesto
                let tvrtka_mjesto_input = {
                    name: 'tvrtka_mjesto',
                    type: 'input',
                    title: 'Mjesto',
                    value: tvrtka_mjesto,
                    width: 4,
                    error: vm.errors['tvrtka_mjesto'] ? vm.errors['tvrtka_mjesto'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }

                let tvrtka_postanski_broj = ''
                if( vm.action === 'show' )
                    tvrtka_postanski_broj = vm.data.prijava_tvrtke.postanski_broj
                let tvrtka_postanski_broj_input = {
                    name: 'tvrtka_postanski_broj',
                    type: 'input',
                    title: 'Poštanski broj',
                    value: tvrtka_postanski_broj,
                    width: 4,
                    error: vm.errors['tvrtka_postanski_broj'] ? vm.errors['tvrtka_postanski_broj'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }

                let tvrtka_email = ''
                if( vm.action === 'show' )
                    tvrtka_email = vm.data.prijava_tvrtke.email
                let tvrtka_email_input = {
                    name: 'tvrtka_email',
                    type: 'input',
                    title: 'Email',
                    value: tvrtka_email,
                    width: 4,
                    error: vm.errors['tvrtka_email'] ? vm.errors['tvrtka_email'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }

                let tvrtka_telefon = ''
                if( vm.action === 'show' )
                    tvrtka_telefon = vm.data.prijava_tvrtke.telefon
                let tvrtka_telefon_input = {
                    name: 'tvrtka_telefon',
                    type: 'input',
                    title: 'Telefon',
                    value: tvrtka_telefon,
                    width: 4,
                    error: vm.errors['tvrtka_telefon'] ? vm.errors['tvrtka_telefon'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }

                let tvrtka_mobitel = ''
                if( vm.action === 'show' )
                    tvrtka_mobitel = vm.data.prijava_tvrtke.mobitel
                let tvrtka_mobitel_input = {
                    name: 'tvrtka_mobitel',
                    type: 'input',
                    title: 'Mobitel',
                    value: tvrtka_mobitel,
                    width: 4,
                    error: vm.errors['tvrtka_mobitel'] ? vm.errors['tvrtka_mobitel'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }


                inputs2.push( tvrtka_oib_input )
                inputs2.push( tvrtka_naziv_input )
                inputs2.push( tvrtka_adresa_input )
                inputs2.push( tvrtka_mjesto_input )
                inputs2.push( tvrtka_postanski_broj_input )
                inputs2.push( tvrtka_email_input )
                inputs2.push( tvrtka_telefon_input )
                inputs2.push( tvrtka_mobitel_input )

                sections.push({
                    name: 'Tvrtka zaposlenja',
                    width: 12,
                    inputs: inputs2,
                })
            }



            /**
             * OSOBE U PRATNJI
             */
            if( vm.skup.broj_osoba_u_pratnji ){
                /**
                 * todo:: inputs for osobe u pratnji
                 */
                sections.push({
                    name: 'Osobe u pratnji',
                    width: 12,
                    inputs: inputs3,
                })
            }

            /**
             * DODATNI SADRŽAJ
             */
            if( vm.skup.skupovi_dodatni_sadrzaji > 0 ){
                /**
                 * todo inputs fro  dodatni sadršaj
                 */
                sections.push({
                    name: 'Dodatni sadržaj',
                    width: 12,
                    inputs: inputs4,
                })
            }


            /**
             * PREDRAČUN
             */
            if( vm.skup.kotizacija_skupa ){
                let predracun_select_options = []
                predracun_select_options.push( {
                    key: 'sudionk',
                    value: 'Sudionik'
                } )
                predracun_select_options.push( {
                    key: 'tvrtka',
                    value: 'Tvrtka',
                } )

                let predracun = ''
                if( vm.action === 'show' )
                    predracun = {
                        key: ['sudionik', 'tvrtka' ].includes( vm.data.prijave_predracuni.predracun ) ? vm.data.prijave_predracuni.predracun : '',
                        value: ['sudionik', 'tvrtka' ].includes( vm.data.prijave_predracuni.predracun ) ? ( vm.data.prijave_predracuni.predracun === 'sudionik' ? 'Sudionik' : 'Tvrtka' ) : '',
                    }

                let predracun_input = {
                    name: 'predracun',
                    type: 'select',
                    title: 'Predračun',
                    value: predracun,
                    width: 4,
                    error: vm.errors['predracun'] ? vm.errors['predracun'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        config: {
                            options: predracun_select_options,
                            multiple: false,
                        },
                        title_width: 3,
                        input_width: 9
                    }
                }

                let predracun_email = ''
                if( vm.action == 'show' ){
                    predracun_email = vm.data.prijave_predracuni.email
                }
                let predracun_email_input = {
                    name: 'predracun_email',
                    type: 'input',
                    title: 'Email',
                    value: predracun_email,
                    width: 4,
                    error: vm.errors['predracun_email'] ? vm.errors['predracun_email'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }

                inputs5.push( predracun_input )
                inputs5.push( predracun_email_input )

                sections.push({
                    name: 'Predračun',
                    width: 12,
                    inputs: inputs5,
                })
            }


            /**
             * NAPOMENE
             */
            if( vm.skup.opis2 ){
                let napomene = vm.skup.opis2
                let napomene_input = {
                    name: 'napomene_skupa',
                    type: 'editor',
                    title: '',
                    value: napomene,
                    width: 12,
                    error: vm.errors['napomene'] ? vm.errors['napomene'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: true,
                    disabled: true,
                    config: {
                        title_width: 0,
                        input_width: 12
                    }
                }

                inputs6.push( napomene_input )

                sections.push({
                    name: 'Napomene',
                    width: 12,
                    inputs: inputs6,
                })
            }


            /**
             * GDPR
             */
            let gdpr_text = vm.skup.gdpr_text
            let gdpr_text_input = {
                name: 'gdpr_text',
                type: 'editor',
                title: '',
                value: gdpr_text,
                width: 6,
                error: vm.errors['gdpr_text'] ? vm.errors['gdpr_text'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: true,
                disabled: true,
                config: {
                    title_width: 0,
                    input_width: 12
                }
            }

            let gdpr = ''
            gdpr = {
                key: 1,
                value: 'Da'
            }
            let gdpr_input = {
                name: 'gdpr',
                type: 'select',
                title: 'Prihvaćam',
                value: gdpr,
                width: 12,
                error: vm.errors['gdpr'] ? vm.errors['gdpr'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: true,
                readonly: true,
                disabled: true,
                config: {
                    options: [
                        {
                            key: 1,
                            value: 'Da'
                        },
                        {
                            key: 0,
                            value: 'Ne'
                        }
                    ],
                    multiple: false,
                    title_width: 1,
                    input_width: 2
                }
            }
            inputs7.push(gdpr_text_input)
            inputs7.push(gdpr_input)
            sections.push({
                name: 'GDPR',
                width: 12,
                inputs: inputs7,
            })


            /**
             * FORMA
             */
            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: sections,
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){
            console.log( name, value )
            let vm = this;
            if( name === 'korisnik_id' ){
                vm.open_loader = true
                axios.get( process.env.VUE_APP_API_URL +  'korisnik/' + value )
                    .then( function ( response ) {
                        vm.korisnik = response.data

                        axios.get( process.env.VUE_APP_API_URL +  'izzyedu/osoba/', {
                            params: {
                                filters: {
                                    oib: vm.korisnik.oib ? vm.korisnik.oib : '11111111111'
                                },
                            }
                        })
                            .then( function ( response ) {
                                if( response.data.data.length === 1 )
                                    vm.izzyedu_osoba = response.data.data[0]
                                vm.setupForm()
                                vm.open_loader = false
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                                vm.open_loader = false
                            } )

                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                        vm.open_loader = false
                    } )
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        if( this.skup )
            this.init()
    },
    watch:{
        skup: function(){
            this.init()
        }
    }
}
</script>

<style scoped>

</style>