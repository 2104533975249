<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>

        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        >
        </forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Loading from "vue-loading-overlay";
import Forms from "@/components/form/Forms";
export default {
    name: "PrijavaForma",
    components: { Forms, Loading },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/skupovi/' + this.$route.params.skup_id + '/prijave',
            url: 'prijava',
            data: {},
            skup_id: this.$route.params.skup_id,
            open_loader: false,

            skup: {},

            // lista svih korisnika
            korisnici: [],

            // trenutni selektirani korisnik
            korisnik: {},

            // trenutna izzyedu osoba
            izzyedu_osoba: {},
            izzyedu_osoba_tvrtka: {},
            izzyedu_osoba_tvrtke: [],


            korisnici_select: [],
            spolovi_select: [
                {
                    key: '0',
                    value: 'Ž'
                },
                {
                    key: '1',
                    value: 'M'
                }
            ],
            da_ne_select: [
                {
                    key: '0',
                    value: 'Ne'
                },
                {
                    key: '1',
                    value: 'Da'
                }
            ],
            predracun_select: [],
            vrste_sudionika_select: [],
            kotizacija_vrste_sudionika_select: [],
            suorganizatori_select: [],
        }
    },


    methods: {

        async fetchSkup(){
            let vm = this
            const response = await axios.get( process.env.VUE_APP_API_URL + 'skup' + '/' + vm.skup_id )

            vm.skup = response.data

            // vrste sudionika select options
            if( vm.skup.kotizacija_skupa ){
                vm.skup.skupovi_kotizacije.forEach( item => {
                    let exists = vm.getVrstaSudionika( item.vrsta_sudionika_id.toString() )
                    if( !(typeof exists !== 'undefined') )
                        vm.vrste_sudionika_select.push({
                            key: item.vrste_sudionika.id.toString(),
                            value: item.vrste_sudionika.naziv
                        })
                } )
            } else {
                if( vm.skup.izzy_edu_id ){
                    await this.fetchVrsteSudionika( '1' )
                } else {
                    await this.fetchVrsteSudionika( '0' )
                }
            }


            // set select options for skup
            if( vm.skup.kotizacija_skupa ){
                vm.predracun_select.push( {
                    key: 'sudionik',
                    value: 'Sudionik'
                } )
                if( vm.skup.tvrtka )
                    vm.predracun_select.push( {
                        key: 'tvrtka',
                        value: 'Tvrtka',
                    } )
            }
        },

        async fetchVrsteSudionika( izzy_edu_vrsta_sudionika ){
            let vm = this
            const response = await axios.get( process.env.VUE_APP_API_URL + 'vrsta-sudionika', {
                params:{
                    filters: {
                        izzy_edu_vrsta_sudionika: izzy_edu_vrsta_sudionika
                    }
                }
            } )
            response.data.data.map( function( item ) {
                vm.vrste_sudionika_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        async fetchKorisnici(){
            let vm = this
            const response = await axios.get( process.env.VUE_APP_API_URL + 'korisnik', {
                params:{
                    order: 'asc',
                    order_by: 'prezime'
                }
            } )

            vm.korisnici = response.data.data

            response.data.data.map( function( item ) {
                vm.korisnici_select.push(
                    {
                        key: item.id.toString(),
                        value: item.ime + ' ' + item.prezime + ' (' + item.oib + ')'
                    }
                )
            } )
        },

        async fetchIzzyEduOsobaByOIB( oib ){
            let vm = this
            const response = await axios.get( process.env.VUE_APP_API_URL + 'izzyedu/osoba', {
                params:{
                    filters: {
                        oib: oib || '11111111111'
                    }
                }
            } )
            vm.izzyedu_osoba = response.data.data[0] || {}
            return vm.izzyedu_osoba
        },

        async fetchIzzyEduOsobaTvrtka( ){
            let vm = this
            if( vm.izzyedu_osoba ){
                const response = await axios.get( process.env.VUE_APP_API_URL + 'izzyedu/osoba-tvrtka', {
                    params:{
                        filters: {
                            osoba_id: vm.izzyedu_osoba.id || '-1'
                        }
                    }
                } )

                vm.izzyedu_osoba_tvrtke = response.data.data || {}
                vm.izzyedu_osoba_tvrtka = response.data.data[0] || {}
            }

        },

        async fetchSuorganizatori(){
            let vm = this
            const response = await axios.get( process.env.VUE_APP_API_URL + 'skup-suorganizator', {
                params:{
                    page: 1,
                    par_page: -1,
                    filters: {
                        skup_id: vm.skup_id
                    }
                }
            } )


            response.data.data.forEach( function( item ) {
                vm.suorganizatori_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )

        },

        getKorisnik( id ){
            return this.korisnici_select.find( item => item.key === id )
        },

        getKorisnikData( id ){
            this.korisnik = this.korisnici.find( item => item.id.toString() === id )
            return this.korisnik
        },

        getIzzyEduOsobaTvrtka( tvrtka_oib = null ){
            if( tvrtka_oib )
                this.izzyedu_osoba_tvrtka = this.izzyedu_osoba_tvrtke.find( item => item.tvrtka.oib === tvrtka_oib ) || {}
            else
                this.izzyedu_osoba_tvrtka = this.izzyedu_osoba_tvrtke[0] || {}
            return this.izzyedu_osoba_tvrtka
        },

        getSpol( id ){
            return this.spolovi_select.find( item => item.key === id )
        },

        getDaNe( id ){
            return this.da_ne_select.find( item => item.key === id )
        },

        getPredracun( id ){
            return this.predracun_select.find( item => item.key === id )
        },

        getVrstaSudionikaArray( ids ){
            let vm = this
            let ret = []
            ids.forEach( id => {
                ret.push( vm.vrste_sudionika_select.find( item => item.key === id ) )
            } )
            return ret
        },

        getVrstaSudionika( id ){
            return this.vrste_sudionika_select.find( item => item.key === id )
        },

        getKotizacijaVrstaSudionika( id ){
            return this.kotizacija_vrste_sudionika_select.find( item => item.key === id )
        },

        getSuorganizator( id ){
            return this.suorganizatori_select.find( item => item.key === id )
        },

        async init(){
            let vm = this

            await vm.fetchSkup()
            await vm.fetchKorisnici()
            await vm.fetchSuorganizatori()

            if ( vm.action === 'edit' || vm.action === 'show' ){
                vm.open_loader = true;


                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( function ( response ) {

                        let nositelj_prijave = response.data.prijave_osobe.find( item => item.vrsta_kotizacije_id = 1 )


                        // admin
                        vm.data.send_email = '0'
                        vm.data.otkazano = [0,1,'0','1'].includes(response.data.otkazano) ? response.data.otkazano.toString() : '0'
                        vm.data.vrsta_sudionika_ids = []

                        nositelj_prijave.prijave_osobe_vrste_sudionika.forEach( item => {
                            vm.data.vrsta_sudionika_ids.push( item.vrsta_sudionika_id.toString() )
                            let tmp_vrsta_sudionika = vm.getVrstaSudionika( item.vrsta_sudionika_id.toString() )
                            if( tmp_vrsta_sudionika )
                                vm.kotizacija_vrste_sudionika_select.push( tmp_vrsta_sudionika  )
                        }  )

                        vm.data.vrsta_sudionika_ids.forEach( vrsta_sudionika_id => {
                            let tmp_vrsta_sudionika = vm.getVrstaSudionika( vrsta_sudionika_id)
                            if( tmp_vrsta_sudionika )
                                vm.kotizacija_vrste_sudionika_select.push( tmp_vrsta_sudionika )
                        } )
                        vm.data.kotizacija_vrsta_sudionika_id = response.data.prijave_skupovi_kotizacije[0] ? response.data.prijave_skupovi_kotizacije[0].skupovi_kotizacije.vrsta_sudionika_id.toString() : ''

                        vm.data.skup_suorganizator_id = nositelj_prijave.skup_suorganizator_id ? nositelj_prijave.skup_suorganizator_id.toString() : ''

                        if( response.data.prijave_predracuni ){
                            vm.data.ponuda_placena = response.data.prijave_predracuni.ponuda_placena.toString()
                            vm.data.datum_knjizenja_uplate = response.data.prijave_predracuni.datum_knjizenja_uplate
                            vm.data.iznos = response.data.prijave_predracuni.iznos
                            vm.data.uplatitelj_naziv = response.data.prijave_predracuni.uplatitelj_naziv
                            vm.data.uplatitelj_oib = response.data.prijave_predracuni.uplatitelj_oib
                            vm.data.napomena = response.data.prijave_predracuni.napomena
                        }


                        // inputs1 -> nositelj prijave
                        vm.data.ime = nositelj_prijave.ime
                        vm.data.prezime = nositelj_prijave.prezime
                        vm.data.oib = nositelj_prijave.oib
                        vm.data.adresa = nositelj_prijave.adresa
                        vm.data.mjesto = nositelj_prijave.mjesto
                        vm.data.postanski_broj = nositelj_prijave.postanski_broj
                        vm.data.telefon = nositelj_prijave.telefon
                        vm.data.email = nositelj_prijave.email
                        vm.data.mobitel = nositelj_prijave.mobitel
                        vm.data.spol = nositelj_prijave.spol.toString()
                        vm.data.akademski_stupanj = nositelj_prijave.akademski_stupanj
                        vm.data.strucni_stupanj = nositelj_prijave.strucni_stupanj


                        //inputs 2 --> tvrtka
                        if( vm.skup.tvrtka && response.data.prijave_tvrtke) {
                            vm.data.tvrtka_oib = response.data.prijave_tvrtke.tvrtka_oib
                            vm.data.tvrtka_naziv = response.data.prijave_tvrtke.tvrtka_naziv
                            vm.data.tvrtka_adresa = response.data.prijave_tvrtke.tvrtka_adresa
                            vm.data.tvrtka_mjesto = response.data.prijave_tvrtke.tvrtka_mjesto
                            vm.data.tvrtka_postanski_broj = response.data.prijave_tvrtke.tvrtka_postanski_broj
                            vm.data.tvrtka_email = response.data.prijave_tvrtke.tvrtka_email
                            vm.data.tvrtka_telefon = response.data.prijave_tvrtke.tvrtka_telefon
                            vm.data.tvrtka_mobitel = response.data.prijave_tvrtke.tvrtka_mobitel
                        }


                        // inputs 3 -> osobe u pratnji
                        let i = 1
                        response.data.prijave_osobe.forEach( item => {
                            if( item.vrsta_kotizacije_id.toString() === '2' ){
                                vm.data[ 'pratnja_ime_' + i ] = item.ime
                                vm.data[ 'pratnja_prezime_' + i ] = item.prezime
                                vm.data[ 'pratnja_oib_' + i ] = item.oib
                                i++
                            }
                        } )

                        // inputs 4 -> dodatni sadržaj
                        vm.skup.skupovi_dodatni_sadrzaji.forEach( item => {
                            vm.data['dodatni_sadrzaj_' + item.id ] = response.data.prijave_skupovi_dodatni_sadraji.find( prijava_skup_dodatni_sadrzaj => prijava_skup_dodatni_sadrzaj.skup_dodatni_sadrzaj_id === item.id ) ? 1 : 0
                        } )


                        // inputs 8 -> teme
                        vm.skup.skupovi_teme.forEach( item => {
                            vm.data['tema_' + item.id ] = response.data.prijave_teme.find( prijava_tema => prijava_tema.skup_tema_id === item.id ) ? 1 : 0
                        } )


                        // inputs 5 -> kotizacija
                        if( vm.skup.kotizacija_skupa && response.data.prijave_predracuni ){
                            vm.data.predracun = response.data.prijave_predracuni.predracun || null
                            vm.data.ponuda_email = response.data.prijave_predracuni.ponuda_email || null
                        }

                        // gdpr
                        vm.data.gdpr = 1

                        vm.setupForm()
                        vm.open_loader = false;
                        vm.$emit( 'returnData', response.data )
                    } )
                    // .catch( function ( error ) {
                    //     vm.open_loader = false
                    //     if(error instanceof Error) {
                    //         console.log( 'Error: ' . error);
                    //     } else {
                    //         console.log( 'Unexpected response: ' . error);
                    //     }
                    // } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.data['vrsta_sudionika_ids'] = []
                if( vm.skup.izzy_edu_id )
                    vm.data.vrsta_sudionika_ids.push( '2' )
                else
                    vm.data.vrsta_sudionika_ids.push( '1' )
                vm.setupForm()
            }



        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs0 = [];
            let inputs_admin = []
            let inputs1 = [];
            let inputs2 = [];
            let inputs3 = [];
            let inputs4 = [];
            let inputs5 = [];
            let inputs6 = [];
            let inputs7 = [];
            let inputs8 = [];

            let sections = [];

            /**
             * SKUP
             */

            let skup_id_input = {
                name: 'skup_id',
                type: 'hidden',
                value: vm.skup_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            };

            let naziv_skupa_input = {
                name: 'naziv skupa',
                type: 'editor',
                title: '',
                value: vm.skup.opis,
                width: 12,
                error: vm.errors['naziv_skupa'] ? vm.errors['naziv_skupa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: true,
                disabled: true,
                config: {
                    title_width: 0,
                    input_width: 12
                }
            }

            inputs0.push( naziv_skupa_input )
            inputs0.push( skup_id_input )
            sections.push( {
                name: 'Događaj',
                width: 12,
                inputs: inputs0,
            } )

            /**
             * administracija
             */


            let admin_input = {
                name: 'admin',
                type: 'hidden',
                value: 1,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            };

            let korisnik_id_input = {
                name: 'korisnik_id',
                type: 'select',
                title: 'Korisnik',
                value: vm.getKorisnik( vm.data.korisnik_id ),
                width: 12,
                error: vm.errors['korisnik_id'] ? vm.errors['korisnik_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.korisnici_select,
                    multiple: false,
                    title_width: 2,
                    input_width: 4
                }
            }


            let send_email_input = {
                name: 'send_email',
                type: 'select',
                title: 'Pošalji korisniku email',
                value: vm.getDaNe( vm.data.send_email ),
                width: 12,
                error: vm.errors[ 'send_email' ] ? vm.errors[ 'send_email' ] : '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.da_ne_select,
                    title_width: 2,
                    input_width: 2
                }
            }


            let otkazano_input = {
                name: 'otkazano',
                type: 'select',
                title: 'Otkazano',
                value: vm.getDaNe( vm.data.otkazano ),
                width: 12,
                error: vm.errors['otkazano'] ? vm.errors['otkazano'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.da_ne_select,
                    multiple: false,
                    title_width: 2,
                    input_width: 2
                }
            }


            let vrsta_sudionika_ids_input = {
                name: 'vrsta_sudionika_ids',
                type: 'select',
                title: 'Vrsta sudionika',
                value: vm.getVrstaSudionikaArray( vm.data.vrsta_sudionika_ids ),
                width: 4,
                error: vm.errors['vrsta_sudionika_ids'] ? vm.errors['vrsta_sudionika_ids'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.vrste_sudionika_select,
                    multiple: true,
                    title_width: 6,
                    input_width: 6
                }
            }


            let kotizacija_vrsta_sudionika_id_input = {
                name: 'kotizacija_vrsta_sudionika_id',
                type: 'select',
                title: 'Kotizacija',
                value: vm.getKotizacijaVrstaSudionika( vm.data.kotizacija_vrsta_sudionika_id ),
                width: 4,
                error: vm.errors['kotizacija_vrsta_sudionika_id'] ? vm.errors['kotizacija_vrsta_sudionika_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.kotizacija_vrste_sudionika_select,
                    multiple: false,
                    title_width: 4,
                    input_width: 8
                }
            }

            let skup_suorganizator_id_input = {
                name: 'skup_suorganizator_id',
                type: 'select',
                title: 'Suorganizator',
                value: vm.getSuorganizator( vm.data.skup_suorganizator_id ),
                width: 4,
                error: vm.errors['skup_suorganizator_id'] ? vm.errors['skup_suorganizator_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.suorganizatori_select,
                    multiple: false,
                    title_width: 4,
                    input_width: 8
                }
            }

            let placeno_input = {
                name: 'ponuda_placena',
                type: 'select',
                title: 'Placeno',
                value: vm.getDaNe( vm.data.ponuda_placena ),
                width: 4,
                error: vm.errors['ponuda_placena'] ? vm.errors['ponuda_placena'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.da_ne_select,
                    multiple: false,
                    title_width: 6,
                    input_width: 6
                }
            }

            let datum_uplate_imput = {
                name: 'datum_knjizenja_uplate',
                type: 'datepicker',
                title: 'Datum uplate',
                value: vm.data.datum_knjizenja_uplate,
                width: 4,
                error: vm.errors['datum_knjizenja_uplate'] ? vm.errors['datum_knjizenja_uplate'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode:'date',
                    title_width: 4,
                    input_width: 8
                }
            }


            let iznos_input = {
                name: 'iznos',
                type: 'input',
                title: 'Iznos',
                value: vm.data.iznos,
                width: 4,
                error: vm.errors['iznos'] ? vm.errors['iznos'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }


            let uplatitelj_input = {
                name: 'uplatitelj_naziv',
                type: 'input',
                title: 'Uplatitelj',
                value: vm.data.uplatitelj_naziv,
                width: 8,
                error: vm.errors['uplatitelj_naziv'] ? vm.errors['uplatitelj_naziv'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let uplatitelj_oib_input = {
                name: 'uplatitelj_oib',
                type: 'input',
                title: 'Uplatitelj OIB',
                value: vm.data.uplatitelj_oib,
                width: 4,
                error: vm.errors['uplatitelj_oib'] ? vm.errors['uplatitelj_oib'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }

            let napomena_input = {
                name: 'napomena',
                type: 'input',
                title: 'Napomena',
                value: vm.data.napomena,
                width: 12,
                error: vm.errors['napomena'] ? vm.errors['napomena'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }


            if( vm.action === 'create' || vm.action === 'edit' ){

                if( vm.action === 'create' )
                    inputs_admin.push( korisnik_id_input )
                inputs_admin.push( admin_input )
                inputs_admin.push( send_email_input )
            }
            inputs_admin.push( otkazano_input )

            inputs_admin.push( vrsta_sudionika_ids_input )
            if( vm.skup.kotizacija_skupa ){
                inputs_admin.push( kotizacija_vrsta_sudionika_id_input )
                inputs_admin.push( skup_suorganizator_id_input )
                inputs_admin.push( placeno_input )
                inputs_admin.push( datum_uplate_imput )
                inputs_admin.push( iznos_input )
                inputs_admin.push( uplatitelj_input )
                inputs_admin.push( uplatitelj_oib_input )
                inputs_admin.push( napomena_input )
            }

            sections.push(
                {
                    name: 'Administracija',
                    width: 12,
                    inputs: inputs_admin,
                }
            )


            /**
             * OSOBA
             */



            let ime_input = {
                name: 'ime',
                type: 'input',
                title: 'Ime',
                value: vm.data.ime,
                width: 4,
                error: vm.errors['ime'] ? vm.errors['ime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let prezime_input = {
                name: 'prezime',
                type: 'input',
                title: 'Prezime',
                value: vm.data.prezime,
                width: 4,
                error: vm.errors['preime'] ? vm.errors['preime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let oib_input = {
                name: 'oib',
                type: 'input',
                title: 'OIB',
                value: vm.data.oib,
                width: 4,
                error: vm.errors['oib'] ? vm.errors['oib'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let adresa_input = {
                name: 'adresa',
                type: 'input',
                title: 'Adresa',
                value: vm.data.adresa,
                width: 4,
                error: vm.errors['adresa'] ? vm.errors['adresa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let mjesto_input = {
                name: 'mjesto',
                type: 'input',
                title: 'Mjesto',
                value: vm.data.mjesto,
                width: 4,
                error: vm.errors['mjesto'] ? vm.errors['mjesto'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let postanski_broj_input = {
                name: 'postanski_broj',
                type: 'input',
                title: 'Poštanski broj',
                value: vm.data.postanski_broj,
                width: 4,
                error: vm.errors['postanski_broj'] ? vm.errors['postanski_broj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let telefon_input = {
                name: 'telefon',
                type: 'input',
                title: 'Telefon',
                value: vm.data.telefon,
                width: 4,
                error: vm.errors['telefon'] ? vm.errors['telefon'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let email_input = {
                name: 'email',
                type: 'input',
                title: 'Email',
                value: vm.data.email,
                width: 4,
                error: vm.errors['email'] ? vm.errors['email'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let mobitel_input = {
                name: 'mobitel',
                type: 'input',
                title: 'Mobitel',
                value: vm.data.mobitel,
                width: 4,
                error: vm.errors['mobitel'] ? vm.errors['mobitel'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let spol_input = {
                name: 'spol',
                type: 'select',
                title: 'Spol',
                value: vm.getSpol( vm.data.spol ),
                width: 4,
                error: vm.errors['spol'] ? vm.errors['spol'] :  '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.spolovi_select,
                    title_width: 3,
                    input_width: 9
                }
            }


            let akademski_stupanj_input = {
                name: 'akademski_stupanj',
                type: 'input',
                title: 'Akademska titula',
                value: vm.data.akademski_stupanj,
                width: 4,
                error: vm.errors['akademski_stupanj'] ? vm.errors['akademski_stupanj'] : '',
                note: 'mr.sc., dr.sc. i sl.',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let strucni_stupanj_input = {
                name: 'strucni_stupanj',
                type: 'input',
                title: 'Stručna titula',
                value: vm.data.strucni_stupanj,
                width: 4,
                error: vm.errors['strucni_stupanj'] ? vm.errors['strucni_stupanj'] : '',
                note: process.env.VUE_APP_APP_OWNER === 'HKIG' ? 'ing.građ., struč.spec.ing.aedif., dipl.ing.građ., mag.ing.aedif. i sl.' : '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            inputs1.push( oib_input )
            inputs1.push( ime_input )
            inputs1.push( prezime_input )
            inputs1.push( adresa_input )
            inputs1.push( mjesto_input )
            inputs1.push( postanski_broj_input )
            inputs1.push( telefon_input )
            inputs1.push( email_input )
            inputs1.push( mobitel_input )
            inputs1.push( spol_input )
            inputs1.push( strucni_stupanj_input )
            inputs1.push( akademski_stupanj_input )

            sections.push(
                    {
                        name: 'Osobni podatci',
                        width: 12,
                        inputs: inputs1,
                    }
            )


            /**
             * TVRTKA
             */
            if( vm.skup.tvrtka ){
                let tvrtka_oib_input = {
                    name: 'tvrtka_oib',
                    type: 'input',
                    title: 'OIB tvrtke',
                    value: vm.data.tvrtka_oib,
                    width: 4,
                    error: vm.errors['tvrtka_oib'] ? vm.errors['tvrtka_oib'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }


                let tvrtka_naziv_input = {
                    name: 'tvrtka_naziv',
                    type: 'input',
                    title: 'Naziv tvrtke',
                    value: vm.data.tvrtka_naziv,
                    width: 6,
                    error: vm.errors['tvrtka_naziv'] ? vm.errors['tvrtka_naziv'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 2,
                        input_width: 10
                    }
                }


                let tvrtka_adresa_input = {
                    name: 'tvrtka_adresa',
                    type: 'input',
                    title: 'Adresa',
                    value: vm.data.tvrtka_adresa,
                    width: 4,
                    error: vm.errors['tvrtka_adresa'] ? vm.errors['tvrtka_adresa'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }


                let tvrtka_mjesto_input = {
                    name: 'tvrtka_mjesto',
                    type: 'input',
                    title: 'Mjesto',
                    value: vm.data.tvrtka_mjesto,
                    width: 4,
                    error: vm.errors['tvrtka_mjesto'] ? vm.errors['tvrtka_mjesto'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }


                let tvrtka_postanski_broj_input = {
                    name: 'tvrtka_postanski_broj',
                    type: 'input',
                    title: 'Poštanski broj',
                    value: vm.data.tvrtka_postanski_broj,
                    width: 4,
                    error: vm.errors['tvrtka_postanski_broj'] ? vm.errors['tvrtka_postanski_broj'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }


                let tvrtka_email_input = {
                    name: 'tvrtka_email',
                    type: 'input',
                    title: 'Email',
                    value: vm.data.tvrtka_email,
                    width: 4,
                    error: vm.errors['tvrtka_email'] ? vm.errors['tvrtka_email'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }


                let tvrtka_telefon_input = {
                    name: 'tvrtka_telefon',
                    type: 'input',
                    title: 'Telefon',
                    value: vm.data.tvrtka_telefon,
                    width: 4,
                    error: vm.errors['tvrtka_telefon'] ? vm.errors['tvrtka_telefon'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }


                let tvrtka_mobitel_input = {
                    name: 'tvrtka_mobitel',
                    type: 'input',
                    title: 'Mobitel',
                    value: vm.data.tvrtka_mobitel,
                    width: 4,
                    error: vm.errors['tvrtka_mobitel'] ? vm.errors['tvrtka_mobitel'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }

                inputs2.push( tvrtka_oib_input )
                inputs2.push( tvrtka_naziv_input )
                inputs2.push( tvrtka_adresa_input )
                inputs2.push( tvrtka_mjesto_input )
                inputs2.push( tvrtka_postanski_broj_input )
                inputs2.push( tvrtka_email_input )
                inputs2.push( tvrtka_telefon_input )
                inputs2.push( tvrtka_mobitel_input )



                sections.push({
                    name: 'Tvrtka zaposlenja',
                    width: 12,
                    inputs: inputs2,
                })
            }



            /**
             * OSOBE U PRATNJI
             */
            if( vm.skup.broj_osoba_u_pratnji > 0 ){


                for( let i = 1; i <= vm.skup.broj_osoba_u_pratnji; i++ ){

                    let pratnja_ime_input = {
                        name: 'pratnja_ime_' + i,
                        type: 'input',
                        title: 'Ime',
                        value: vm.data['pratnja_ime_' + i],
                        width: 4,
                        error: vm.errors['pratnja_ime_' + i] ? vm.errors['pratnja_ime_' + i] : '',
                        note: '',
                        explanation: '',
                        placeholder: '',
                        required: false,
                        readonly: vm.disabled,
                        disabled: vm.disabled,
                        config: {
                            title_width: 3,
                            input_width: 9
                        }
                    }


                    let pratnja_prezime_input = {
                        name: 'pratnja_prezime_' + i ,
                        type: 'input',
                        title: 'Prezime',
                        value: vm.data['pratnja_prezime_' + i],
                        width: 4,
                        error: vm.errors['pratnja_prezime_' + i] ? vm.errors['pratnja_prezime_' + i] : '',
                        note: '',
                        explanation: '',
                        placeholder: '',
                        required: false,
                        readonly: vm.disabled,
                        disabled: vm.disabled,
                        config: {
                            title_width: 3,
                            input_width: 9
                        }
                    }


                    let pratnja_oib_input = {
                        name: 'pratnja_oib_' + i,
                        type: 'input',
                        title: 'OIB',
                        value: vm.data['pratnja_oib_' + i],
                        width: 4,
                        error: vm.errors['pratnja_oib_' + i] ? vm.errors['pratnja_oib_' + i] : '',
                        note: '',
                        explanation: '',
                        placeholder: '',
                        required: false,
                        readonly: vm.disabled,
                        disabled: vm.disabled,
                        config: {
                            title_width: 3,
                            input_width: 9
                        }
                    }

                    inputs3.push( pratnja_ime_input )
                    inputs3.push( pratnja_prezime_input )
                    inputs3.push( pratnja_oib_input )
                }

                sections.push({
                    name: 'Osobe u pratnji',
                    width: 12,
                    inputs: inputs3,
                })
            }


            if( vm.skup.skupovi_dodatni_sadrzaji.length > 0 ){

                vm.skup.skupovi_dodatni_sadrzaji.forEach( function( sds ) {
                    inputs4.push( {
                        name: 'dodatni_sadrzaj_' + sds.id,
                        type: 'checkbox',
                        title: sds.dodatni_sadrzaji.naziv + ' (' + sds.dodatni_sadrzaji.opis + ') ',
                        value: vm.data[ 'dodatni_sadrzaj_' + sds.id ],
                        width: 12,
                        error: vm.errors[ 'dodatni_sadrzaj_' + sds.id ] ? vm.errors[ 'dodatni_sadrzaj_' + sds.id ] : '',
                        note: sds.napomena,
                        explanation: '',
                        placeholder: '',
                        required: false,
                        readonly: vm.disabled,
                        disabled: vm.disabled,
                        config: {
                            title_width: 0,
                            input_width: 6
                        }
                    } )
                })

                sections.push({
                    name: 'Dodatni sadržaj',
                    width: 12,
                    inputs: inputs4,
                })
            }

            /**
             *  TEME
             */
            if( vm.skup.skupovi_teme.length > 0 ){

                vm.skup.skupovi_teme.forEach( function( tema ) {
                    if( tema.vidljiva_na_prijavi )
                        inputs8.push( {
                            name: 'tema_' + tema.id,
                            type: 'checkbox',
                            title: tema.broj_teme + ' ' + tema.naziv + ' (' + tema.datum_od + ' - ' + tema.datum_do + ') ',
                            value: vm.data['tema_' + tema.id],
                            width: 12,
                            error: vm.errors[ 'tema_' + tema.id ] ? vm.errors[ 'tema_' + tema.id ] : '',
                            note: '',
                            explanation: '',
                            placeholder: '',
                            required: false,
                            readonly: vm.disabled,
                            disabled: vm.disabled,
                            config: {
                                title_width: 0,
                                input_width: 6
                            }
                        } )
                })


                sections.push({
                    name: 'Odaberite teme na kojima želite sudjelovati, označite ih kvačicom.',
                    width: 12,
                    inputs: inputs8
                })
            }


            /**
             * PREDRAČUN
             */
            if( vm.skup.kotizacija_skupa ){
                let predracun_input = {
                    name: 'predracun',
                    type: 'select',
                    title: 'Predračun',
                    value: vm.getPredracun( vm.data.predracun ) ,
                    width: 4,
                    error: vm.errors['predracun'] ? vm.errors['predracun'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        options: vm.predracun_select,
                        title_width: 3,
                        input_width: 9
                    }
                }


                let predracun_email_input = {
                    name: 'ponuda_email',
                    type: 'input',
                    title: 'Email',
                    value: vm.data.ponuda_email,
                    width: 4,
                    error: vm.errors['ponuda_email'] ? vm.errors['ponuda_email'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 3,
                        input_width: 9
                    }
                }

                inputs5.push( predracun_input )
                inputs5.push( predracun_email_input )

                sections.push({
                    name: 'Predračun',
                    width: 12,
                    inputs: inputs5,
                })
            }


            /**
             * NAPOMENE
             */
            if( vm.skup.opis2 ){
                let napomene = vm.skup.opis2
                let napomene_input = {
                    name: 'napomene_skupa',
                    type: 'editor',
                    title: '',
                    value: napomene,
                    width: 12,
                    error: vm.errors['napomene'] ? vm.errors['napomene'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: true,
                    disabled: true,
                    config: {
                        title_width: 0,
                        input_width: 12
                    }
                }

                inputs6.push( napomene_input )

                sections.push({
                    name: 'Napomene',
                    width: 12,
                    inputs: inputs6,
                })
            }


            /**
             * GDPR
             */
            let gdpr_text = vm.skup.gdpr_text
            let gdpr_text_input = {
                name: 'gdpr_text',
                type: 'editor',
                title: '',
                value: gdpr_text,
                width: 12,
                error: vm.errors['gdpr_text'] ? vm.errors['gdpr_text'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: true,
                disabled: true,
                config: {
                    title_width: 0,
                    input_width: 12
                }
            }

            let gdpr = ''
            gdpr = {
                key: 1,
                value: 'Da'
            }
            let gdpr_input = {
                name: 'gdpr',
                type: 'select',
                title: 'Prihvaćam',
                value: gdpr,
                width: 12,
                error: vm.errors['gdpr'] ? vm.errors['gdpr'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: true,
                readonly: true,
                disabled: true,
                config: {
                    options: [
                        {
                            key: 1,
                            value: 'Da'
                        },
                        {
                            key: 0,
                            value: 'Ne'
                        }
                    ],
                    multiple: false,
                    title_width: 2,
                    input_width: 2
                }
            }
            inputs7.push(gdpr_text_input)
            inputs7.push(gdpr_input)
            sections.push({
                name: 'GDPR',
                width: 12,
                inputs: inputs7,
            })


            /**
             * FORMA
             */
            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: sections,
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        async onInput( name, value ){

            let vm = this;
            vm.open_loader = true

            if( vm.data[name] !== value ){


                if( name === 'korisnik_id' &&  value.length ){


                    vm.data.korisnik_id = value
                    vm.getKorisnikData( value )

                    await vm.fetchIzzyEduOsobaByOIB( vm.korisnik.oib )
                    await vm.fetchIzzyEduOsobaTvrtka( vm.korisnik.oib )

                    vm.data.vrsta_sudionika_ids = []
                    vm.data.kotizacija_vrsta_sudionika_id = ''
                    vm.data.skup_suorganizator_id = ''

                    vm.data.ponuda_placena = vm.getDaNe( '0' )
                    vm.data.datum_knjizenja_uplate = ''
                    vm.data.iznos = ''
                    vm.data.uplatitelj_naziv = ''
                    vm.data.uplatitelj_oib = ''
                    vm.data.napomena = ''

                    vm.data.ime = vm.izzyedu_osoba.ime || vm.korisnik.ime
                    vm.data.prezime = vm.izzyedu_osoba.prezime || vm.korisnik.prezime
                    vm.data.oib = vm.izzyedu_osoba.oib || vm.korisnik.oib
                    vm.data.adresa = vm.izzyedu_osoba.adresa
                    vm.data.mjesto = vm.izzyedu_osoba.posta ? vm.izzyedu_osoba.posta.mjesto : ''
                    vm.data.postanski_broj = vm.izzyedu_osoba.posta ? vm.izzyedu_osoba.posta.postanski_broj : ''
                    vm.data.telefon = vm.izzyedu_osoba.telefon
                    vm.data.email = vm.izzyedu_osoba.email || vm.korisnik.email
                    vm.data.mobitel = vm.izzyedu_osoba.mobitel
                    vm.data.spol = [0,1,'0','1'].includes(vm.izzyedu_osoba.spol) ? vm.izzyedu_osoba.spol.toString() : ''
                    vm.data.akademski_stupanj = vm.izzyedu_osoba.akademski_stupanj
                    vm.data.strucni_stupanj = vm.izzyedu_osoba.strucni_stupanj


                    if( vm.izzyedu_osoba_tvrtka ){
                        vm.data.tvrtka_oib = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.tvrtka.oib : ''
                        vm.data.tvrtka_naziv = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.tvrtka.naziv : ''
                        vm.data.tvrtka_adresa = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.tvrtka.adresa : ''
                        vm.data.tvrtka_mjesto = vm.izzyedu_osoba_tvrtka.tvrtka ? ( vm.izzyedu_osoba_tvrtka.tvrtka.posta ? vm.izzyedu_osoba_tvrtka.tvrtka.posta.mjesto : '' ) : ''
                        vm.data.tvrtka_postanski_broj = vm.izzyedu_osoba_tvrtka.tvrtka ? ( vm.izzyedu_osoba_tvrtka.tvrtka.posta ? vm.izzyedu_osoba_tvrtka.tvrtka.posta.postanski_broj : '' ) : ''
                        vm.data.tvrtka_email = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.email : ''
                        vm.data.tvrtka_telefon = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.telefon : ''
                        vm.data.tvrtka_mobitel = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.mobitel : ''
                    }


                    for( let i = 1; i <= vm.skup.broj_osoba_u_pratnji; i++ ){
                        vm.data[ 'pratnja_ime_' + i ] = ''
                        vm.data[ 'pratnja_prezime_' + i ] = ''
                        vm.data[ 'pratnja_oib_' + i ] = ''
                        i++
                    }

                    vm.skup.skupovi_dodatni_sadrzaji.forEach( function( sds ) {
                        vm.data[ 'dodatni_sadrzaj_' + sds.id ] = false
                    })

                    vm.skup.skupovi_teme.forEach( item => {
                        vm.data['tema_' + item.id ] = 0
                    } )

                    vm.data.predracun = null
                    vm.data.ponuda_email = null

                } else if( name === 'tvrtka_oib' && value && value.length === 11 ){

                    vm.getIzzyEduOsobaTvrtka( value )

                    if( vm.izzyedu_osoba_tvrtka.tvrtka ){
                        vm.data.tvrtka_oib = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.tvrtka.oib : value
                        vm.data.tvrtka_naziv = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.tvrtka.naziv : ''
                        vm.data.tvrtka_adresa = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.tvrtka.adresa : ''
                        vm.data.tvrtka_mjesto = vm.izzyedu_osoba_tvrtka.tvrtka ? ( vm.izzyedu_osoba_tvrtka.tvrtka.posta ? vm.izzyedu_osoba_tvrtka.tvrtka.posta.mjesto : '' ) : ''
                        vm.data.tvrtka_postanski_broj = vm.izzyedu_osoba_tvrtka.tvrtka ? ( vm.izzyedu_osoba_tvrtka.tvrtka.posta ? vm.izzyedu_osoba_tvrtka.tvrtka.posta.postanski_broj : '' ) : ''
                        vm.data.tvrtka_email = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.email : ''
                        vm.data.tvrtka_telefon = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.telefon : ''
                        vm.data.tvrtka_mobitel = vm.izzyedu_osoba_tvrtka.tvrtka ? vm.izzyedu_osoba_tvrtka.mobitel : ''
                    }




                } else if( name === 'predracun' ){
                    vm.data['name'] = value
                    if( value === 'tvrtka' )
                        vm.data['ponuda_email'] = vm.data.tvrtka_email
                    else
                        vm.data['ponuda_email'] = vm.data.email
                } else if ( name === 'vrsta_sudionika_ids' ) {

                    if( vm.skup.izzy_edu_id )
                        vm.data.vrsta_sudionika_ids = ['2']
                    else
                        vm.data.vrsta_sudionika_ids = ['1']

                    value.forEach( vrsta_sudionika_id => {
                        if( !vm.data.vrsta_sudionika_ids.includes( vrsta_sudionika_id ) )
                            vm.data.vrsta_sudionika_ids.push( vrsta_sudionika_id )
                    } )


                    vm.kotizacija_vrste_sudionika_select = []
                    vm.data.vrsta_sudionika_ids.forEach( vrsta_sudionika_id => {
                        vm.kotizacija_vrste_sudionika_select.push( vm.getVrstaSudionika( vrsta_sudionika_id) )
                    } )

                    if( !vm.data.vrsta_sudionika_ids.includes( vm.data.kotizacija_vrsta_sudionika_id ) )
                        vm.data.kotizacija_vrsta_sudionika_id = null

                } else {
                    vm.data[name] = value
                }

                vm.setupForm()
            }

            vm.open_loader = false;

        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    if( data.messages.general ){
                        data.messages.general.forEach( e => {
                            toastr.error( e )
                        } )
                    }
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        this.init()
    },
}
</script>

<style scoped>

</style>
