<template>
    <div>
       <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Dodatni sadržaj: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <skup-dodatni-sadrzaj-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                >
                </skup-dodatni-sadrzaj-forma>
            </div>
        </div>
    </div>
</template>

<script>
import SkupDodatniSadrzajForma from "@/views/pages/Skupovi/SkupoviDodatniSadrzaji/SkupDodatniSadrzajForma";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
export default {
name: "SkupDodatniSadrzajEdit",
    components: {
        SkupDodatniSadrzajForma,
        SkupoviNavigation
    },
    data(){
        return {
            breadcrumbs: [],
            id:this.$route.params.dodatni_sadrzaj_id,
            skup_id: this.$route.params.skup_id,
            skup: {},
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/dodatni-sadrzaji',
                    name: 'Dodatni sadržaji'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/dodatni-sadrzaji/' + this.id,
                    name: 'Dodatni sadržaj: ' + this.naziv
                },
                {
                    path: '/skupovi/' + this.skup_id + '/dodatni-sadrzaji/' + this.id + '/edit',
                    name: 'Uredi dodatni sadržaj: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.dodatni_sadrzaji.naziv + ' | ' + data.dodatni_sadrzaji.opis
            this.setBreadcrumbs()
        },

    },
    mounted() {
    }
}
</script>

<style scoped>

</style>