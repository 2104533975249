<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import axios from "axios"
import toastr from 'toastr'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Forms from "@/components/form/Forms"
export default {
    name: "SkupForma",
    components:{ Forms, Loading },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            komora: process.env.VUE_APP_APP_OWNER,
            skup:null,
            settings: {},
            errors: {},
            route: '/skupovi',
            url: 'skup',
            data: {},
            open_loader: false,

            vrste_skupova: [],
            izzy_edu_skupovi: [],

            // select options
            vrste_skupova_select: [],
            izzy_edu_skupovi_select: [],
            kotizacije_select: [],
            tvrtke_select: [],
            vrste_sudionika_select: [],
            racuni_za_uplate_select: []
        }
    },

    methods: {


        async fetchVrsteSkupova(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'vrsta-skupa', {
                params: {
                    paga: 1,
                    par_page: -1,
                    order: 'asc',
                }
            } )
            return response.data.data.map( function( item ) {
                vm.vrste_skupova_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
                vm.vrste_skupova.push( item )
            } )
        },

        getVrstaSkupa( id ){
            return this.vrste_skupova_select.find( item => item.key === id )
        },

        getVrstaSkupaValue( id, key ){
            let ret = this.vrste_skupova.find( item => item.id.toString() === id )
            return ret ? ret[key] : null
        },

        async fetchIzzyEduSkupovi(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'izzyedu/skup', {
                params: {
                    paga: 1,
                    par_page: 50,
                    order: 'desc',
                }
            } )
            return response.data.data.map( function( item ) {
                vm.izzy_edu_skupovi_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
                vm.izzy_edu_skupovi.push( item )
            } )
        },

        getIzzyEduSkup( id ){
            return this.izzy_edu_skupovi_select.find( item => item.key === id )
        },

        getIzzyEduSkupValue( id, key ){
            let ret = this.izzy_edu_skupovi.find( item => item.id.toString() === id )
            return ret ? ret[key] : null
        },

        async fetchKotizacije(){
            this.kotizacije_select = [
                {
                    key: '0',
                    value: 'Ne'
                },
                {
                    key: '1',
                    value: 'Da'
                }
            ]
        },

        getKotizacija( id ){
            return this.kotizacije_select.find( item => item.key === id )
        },

        async fetchTvrtke(){
            this.tvrtke_select = [
                {
                    key: '0',
                    value: 'Ne'
                },
                {
                    key: '1',
                    value: 'Da'
                }
            ]
        },

        getTvrtke( id ){
            return this.tvrtke_select.find( item => item.key === id )
        },

        async fetchVrsteSudionika(){
            this.vrste_sudionika_select = [
                {
                    key: 'aktivni_clanovi_komore',
                    value: 'Samo aktivni članovi komore'
                },
                {
                    key: 'registrirani_korisnici',
                    value: 'Svi registrirani članovi'
                }
            ]
        },

        getVrstaSudionika( id ){
            return this.vrste_sudionika_select.find( item => item.key === id )
        },

        async fetchRacuniZaUplate(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'racun-za-uplatu', {
                params: {
                    paga: 1,
                    par_page: -1,
                    order: 'desc',
                }
            } )
            return response.data.data.map( function( item ) {
                vm.racuni_za_uplate_select.push(
                    {
                        key: item.id.toString(),
                        value: item.primatelj
                    }
                )
            } )
        },

        getRacunZauplatu( id ){
            return this.racuni_za_uplate_select.find( item => item.key === id )
        },

        async init(){
            let vm = this

            await vm.fetchVrsteSkupova()
            await vm.fetchIzzyEduSkupovi()
            await vm.fetchKotizacije()
            await vm.fetchTvrtke()
            await vm.fetchVrsteSudionika()
            await vm.fetchRacuniZaUplate()

            if ( vm.action === 'edit' || vm.action === 'show' ){
                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( async function ( response ) {


                            vm.data.vrsta_skupa_id = response.data.vrsta_skupa_id ? response.data.vrsta_skupa_id.toString() : ''
                            vm.data.izzy_edu_id = response.data.izzy_edu_id ? response.data.izzy_edu_id.toString() : ''
                            vm.data.naziv = response.data.naziv
                            vm.data.datum_prijave_od = response.data.datum_prijave_od
                            vm.data.datum_prijave_do = response.data.datum_prijave_do
                            vm.data.datum_od = response.data.datum_od
                            vm.data.datum_do = response.data.datum_do
                            vm.data.opis = response.data.opis
                            vm.data.opis2 = response.data.opis2
                            vm.data.gdpr_text = response.data.gdpr_text
                            vm.data.vrsta_sudionika = response.data.vrsta_sudionika
                            vm.data.broj_osoba_u_pratnji = response.data.broj_osoba_u_pratnji
                            vm.data.tvrtka = [ 1,'1'].includes(response.data.tvrtka) ? '1' : '0'
                            vm.data.kotizacija_skupa =[1,'1'].includes( response.data.kotizacija_skupa) ? '1' : '0'
                            vm.data.racun_za_uplatu_id = response.data.racun_za_uplatu_id ? response.data.racun_za_uplatu_id.toString() : ''
                            vm.data.poveznica = response.data.poveznica && response.data.poveznica.data ? ( JSON.parse( response.data.poveznica.data )['redirect_to'] ) : ''
                            vm.data.url = response.data.url
                            vm.data.opis_m = response.data.opis_m

                            if( response.data.lokacija_id ){
                                vm.data.mjesto = response.data.lokacije.mjesto
                                vm.data.posta = response.data.lokacije.posta
                                vm.data.adresa = response.data.lokacije.adresa
                                vm.data.lon = response.data.lokacije.lon
                                vm.data.lat = response.data.lokacije.lat
                            }



                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.data.gdpr_text = 'GDPR - kao sudionik događanja i davatelj osobnih podataka izričito pristajem i ' +
                    'ustupam podatke s obrasca ' + vm.komora + ', u svrhu informiranja i evidencije sudjelovanja ' +
                    'sudionika, obrade podataka za potrebe izvještavanja nadležnih tijela o napretku u provedbi ' +
                    'aktivnosti te promidžbe stručnog usavršavanja ' + vm.komora + ' i neče se koristiti u druge ' +
                    'svrhe, sukladno Općoj uredbi o zaštiti podataka (GDPR) - Uredba (EU) 2016/679'
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){
            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = [];


            let vrsta_skupa_id_input = {
                name: 'vrsta_skupa_id',
                type: 'select',
                title: 'Vrsta skupa',
                value: vm.getVrstaSkupa( vm.data.vrsta_skupa_id ),
                width: 12,
                error: vm.errors['vrsta_skupa_id'] ? vm.errors['vrsta_skupa_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.vrste_skupova_select,
                    multiple: false,
                    title_width: 1,
                    input_width: 11
                }
            }

            let izzy_edu_id_input = {
                name: 'izzy_edu_id',
                type: 'select',
                title: 'IzzyEdu Skup',
                value: vm.getIzzyEduSkup( vm.data.izzy_edu_id ),
                width: 12,
                error: vm.errors['vrsta_skupa_id'] ? vm.errors['vrsta_skupa_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.izzy_edu_skupovi_select,
                    multiple: false,
                    title_width: 1,
                    input_width: 11
                }
            }


            let naziv_input = {
                name: 'naziv',
                type: 'input',
                title: 'Naziv',
                value: vm.data.naziv || ( vm.data.izzy_edu_id ? vm.getIzzyEduSkupValue( vm.data.izzy_edu_id, 'naziv' ) : '' ),
                // vm.izzy_edu_skup && vm.izzy_edu_skup['naziv'] ? ( vm.izzy_edu_skup['naziv'] ) : (vm.data['naziv'] ? vm.data['naziv'] : ''),
                width: 12,
                error: vm.errors['naziv'] ? vm.errors['naziv'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let datum_prijave_od_input = {
                name: 'datum_prijave_od',
                type: 'datepicker',
                title: 'Početak prijave',
                value: vm.data.datum_prijave_od,
                width: 3,
                error: vm.errors['datum_prijave_od'] ? vm.errors['datum_prijave_od'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode:'dateTime', // date // time
                }
            }

            let datum_prijave_do_input = {
                name: 'datum_prijave_do',
                type: 'datepicker',
                title: 'Završetak prijave',
                value: vm.data.datum_prijave_do,
                width: 3,
                error: vm.errors['datum_prijave_do'] ? vm.errors['datum_prijave_do'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode:'dateTime',
                }
            }

            let datum_od_input = {
                name: 'datum_od',
                type: 'datepicker',
                title: 'Početak događaja',
                value: vm.data.datum_od, //vm.izzy_edu_skup['datum_od'] ? vm.izzy_edu_skup['datum_od'] : ( vm.data['datum_od'] ? vm.data['datum_od'] : '' ),
                width: 3,
                error: vm.errors['datum_od'] ? vm.errors['datum_od'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode:'dateTime'
                }
            }

            let datum_do_input = {
                name: 'datum_do',
                type: 'datepicker',
                title: 'Završetak događaja',
                value: vm.data.datum_do, //vm.izzy_edu_skup['datum_do'] ? vm.izzy_edu_skup['datum_do'] : ( vm.data['datum_do'] ? vm.data['datum_do'] : '' ),
                width: 3,
                error: vm.errors['datum_do'] ? vm.errors['datum_do'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode:'dateTime'
                }
            }


            let opis_input = {
                name: 'opis',
                type: 'editor',
                title: 'Header',
                value: vm.data.opis,
                //vm.data['opis'] ? vm.data['opis'] :  ( vm.izzy_edu_skup && vm.izzy_edu_skup['naziv'] ? ( vm.izzy_edu_skup['naziv'] + '<br>' + vm.izzy_edu_skup['datum_od'] + ' - ' + vm.izzy_edu_skup['datum_do'] ) : '' ),
                width: 12,
                error: vm.errors['opsi'] ? vm.errors['opsi'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let opis2_input = {
                name: 'opis2',
                type: 'editor',
                title: 'Napomene',
                value: vm.data.opis2, //vm.data['opis2'] ? vm.data['opis2'] : '',
                width: 12,
                error: vm.errors['opis2'] ? vm.errors['opis2'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let gdpr_input = {
                name: 'gdpr_text',
                type: 'editor',
                title: 'GDPR privola',
                value: vm.data.gdpr_text,
                width: 12,
                error: vm.errors['gdpr_text'] ? vm.errors['gdpr_text'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }


            let vrsta_sudionika_input = {
                name: 'vrsta_sudionika',
                type: 'select',
                title: 'Vrsta sudionika',
                value: vm.getVrstaSudionika( vm.data.vrsta_sudionika ),
                width: 3,
                error: vm.errors['vrsta_sudionika'] ? vm.errors['vrsta_sudionika'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.vrste_sudionika_select,
                    multiple: false,
                    title_width: 4,
                    input_width: 8
                }
            }

            let broj_osoba_u_pratni_input = {
                name: 'broj_osoba_u_pratnji',
                type: 'input',
                title: 'Broj osoba u pratnji',
                value: vm.data.broj_osoba_u_pratnji, //vm.data['broj_osoba_u_pratnji'] ? vm.data['broj_osoba_u_pratnji'] : '',
                width: 3,
                error: vm.errors['broj_osoba_u_pratnji'] ? vm.errors['broj_osoba_u_pratnji'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }

            let tvrtka_input = {
                name: 'tvrtka',
                type: 'select',
                title: 'Tvrtka',
                value: vm.getTvrtke( vm.data.tvrtka ),
                width: 3,
                error: vm.errors['tvrtka'] ? vm.errors['tvrtka'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.tvrtke_select,
                    multiple: false,
                    title_width: 4,
                    input_width: 8
                }
            }

            let kotizacija_skupa_input = {
                name: 'kotizacija_skupa',
                type: 'select',
                title: 'Kotizacija skupa',
                value: vm.getKotizacija( vm.data.kotizacija_skupa ),
                width: 3,
                error: vm.errors['kotizacija_skupa'] ? vm.errors['kotizacija_skupa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.kotizacije_select,
                    multiple: false,
                    title_width: 4,
                    input_width: 8
                }
            }

            let racun_za_uplatu_id_input = {
                name: 'racun_za_uplatu_id',
                type: 'select',
                title: 'Organizator',
                value: vm.getRacunZauplatu( vm.data.racun_za_uplatu_id ),
                width: 6,
                error: vm.errors['vrsta_skupa_id'] ? vm.errors['vrsta_skupa_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.racuni_za_uplate_select,
                    multiple: false,
                    title_width: 2,
                    input_width: 10
                }
            }

            let poveznica_input = {
                name: 'poveznica',
                type: 'input',
                title: 'Poveznica',
                value: vm.data.poveznica,
                width: 12,
                error: vm.errors['poveznica'] ? vm.errors['poveznica'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let url_input = {
                name: 'url',
                type: 'input',
                title: 'Web',
                value: vm.data.url,
                width: 12,
                error: vm.errors['url'] ? vm.errors['url'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 5
                }
            }

            let opis_m_input = {
                name: 'opis_m',
                type: 'input',
                title: 'Opis za mobitele',
                value: vm.data.opis_m,
                width: 12,
                error: vm.errors['opis_m'] ? vm.errors['opis_m'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let mjesto_input = {
                name: 'mjesto',
                type: 'input',
                title: 'Mjesto',
                value: vm.data.mjesto,
                width: 4,
                error: vm.errors['mjesto'] ? vm.errors['mjesto'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let posta_input = {
                name: 'posta',
                type: 'input',
                title: 'Pošta',
                value: vm.data.posta,
                width: 4,
                error: vm.errors['posta'] ? vm.errors['posta'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let adresa_input = {
                name: 'adresa',
                type: 'input',
                title: 'Adresa',
                value: vm.data.adresa,
                width: 4,
                error: vm.errors['adresa'] ? vm.errors['adresa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let lon_input = {
                name: 'lon',
                type: 'input',
                title: 'Lon',
                value: vm.data.lon,
                width: 4,
                error: vm.errors['lon'] ? vm.errors['lon'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            let lat_input = {
                name: 'lat',
                type: 'input',
                title: 'Lat',
                value: vm.data.lat,
                width: 4,
                error: vm.errors['lat'] ? vm.errors['lat'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }

            if( !vm.data.vrsta_skupa_id /*!vm.vrsta_skupa*/ ){
                inputs.push( vrsta_skupa_id_input )
            } else {


                if( vm.getVrstaSkupaValue( vm.data.vrsta_skupa_id, 'prijava_forma' ) ){
                    inputs.push( vrsta_skupa_id_input )

                    if( vm.getVrstaSkupaValue( vm.data.vrsta_skupa_id, 'izzy_edu_api' ) )
                        inputs.push( izzy_edu_id_input )

                    inputs.push(naziv_input)
                    inputs.push(datum_prijave_od_input)
                    inputs.push(datum_prijave_do_input)
                    inputs.push(datum_od_input)
                    inputs.push(datum_do_input)
                    inputs.push(opis_input)
                    inputs.push(opis2_input)
                    inputs.push(gdpr_input)
                    inputs.push( vrsta_sudionika_input )

                    if( !vm.getVrstaSkupaValue( vm.data.vrsta_skupa_id, 'webinar' )
                        && vm.data.vrsta_sudionika === 'registrirani_korisnici'
                    )
                        inputs.push( broj_osoba_u_pratni_input )
                    inputs.push(tvrtka_input)
                    inputs.push(kotizacija_skupa_input)
                    if( vm.data.kotizacija_skupa === '1' )
                        inputs.push(racun_za_uplatu_id_input)

                    inputs.push(url_input)

                    if( !vm.getVrstaSkupaValue( vm.data.vrsta_skupa_id, 'webinar' ) ){
                        if( this.komora === 'HKIG' ){
                            inputs.push(opis_m_input)
                            inputs.push(mjesto_input)
                            inputs.push(posta_input)
                            inputs.push(adresa_input)
                            inputs.push(lon_input)
                            inputs.push(lat_input)
                        }
                    }

                } else if( vm.getVrstaSkupaValue( vm.data.vrsta_skupa_id, 'vanjska_poveznica' ) ){
                    inputs.push( vrsta_skupa_id_input )
                    inputs.push(naziv_input)
                    inputs.push(datum_prijave_od_input)
                    inputs.push(datum_prijave_do_input)
                    inputs.push(opis_input)
                    inputs.push( poveznica_input )
                    inputs.push( vrsta_sudionika_input )
                } else if( vm.getVrstaSkupaValue( vm.data.vrsta_skupa_id, 'prilagodjena_forma' ) ){
                    inputs.push( vrsta_skupa_id_input )
                    inputs.push(naziv_input)
                    inputs.push(datum_prijave_od_input)
                    inputs.push(datum_prijave_do_input)
                    inputs.push(opis_input)
                    inputs.push(opis2_input)
                    inputs.push(gdpr_input)
                    inputs.push( vrsta_sudionika_input )
                } else {
                    inputs.push( vrsta_skupa_id_input )
                }
            }


            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){
            let vm = this
            if( vm.data[name] !== value ){

                if( name === 'izzy_edu_id' ){
                    vm.data.naziv = vm.getIzzyEduSkupValue( value, 'naziv' )

                    vm.data.datum_od = vm.getIzzyEduSkupValue( value, 'datum_i_vrijeme_od' )

                    vm.data.datum_do = vm.getIzzyEduSkupValue( value, 'datum_i_vrijeme_do' )

                    vm.data.opis = vm.data.naziv +
                        '<br>' +
                        vm.data.datum_od +
                        ' - ' +
                        vm.data.datum_do
                }

                if( name === 'vrsta_sudionika' && value === 'aktivni_clanovi_komore' ){
                    vm.data.broj_osoba_u_pratnji = '0'
                }

                vm.data[name] = value
                vm.setupForm()
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }
        },


    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
