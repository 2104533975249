<template>
    <div>
        <breadcrumbs
                :title="'Događaji'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Novi događaj</h5><br>
            </div>
            <div class="ibox-content">
                <skup-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                >
                </skup-forma>
            </div>
        </div>
    </div>

</template>

<script>
import SkupForma from "@/views/pages/Skupovi/SkupForma";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
export default {
name: "EventCreate",
    components: {Breadcrumbs, SkupForma},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi',
                    name: 'Događaji'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>