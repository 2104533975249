<template>
    <div>
        <breadcrumbs
            :title="'Vrste sudionika'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova vrsta sudionika</h5>
            </div>
            <div class="ibox-content">
                <vrsta-sudionika-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></vrsta-sudionika-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import VrstaSudionikaForma from "./VrstaSudionikaForma";
export default {
    name: "VrstaSudionikaCreate",
    components: {
        VrstaSudionikaForma,
        Breadcrumbs
    },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/vrste-sudionika',
                    name: 'Vrste sudionika'
                },
                {
                    path: '/postavke/vrste-sudionika/create',
                    name: 'Nova vrsta sudionika'
                }
            ]
        }
    },
    mounted(){
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
