<template>
    <div>
        <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Dvorane</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="send(null)"
                >
                    Pošalji svima na e-mail
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @buttonClick="buttonClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";
import axios from "axios";
import toastr from "toastr";
export default {
    name: "Qrs",
    components: {
        Datatables,
        SkupoviNavigation
    },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'prijava-osoba',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id
        }
    },
    methods:{

        send( id = null ){
            let vm = this
            let url = process.env.VUE_APP_API_URL + 'prijava-osoba-qr/add-tp-list/' + vm.skup_id;
            if( id ){
                url += '/' + id
            } else {
                url += '/0'
            }
            let confirm = true;
            confirm = window.confirm( 'Da li ste sigurni?');
            if( confirm ){
                axios( {
                    method: 'GET',
                    url: url
                } ).then( ( response )=> {
                    if( response.data.success ){
                        toastr.success( 'Uspješno poslano!')
                        this.setDatatable()
                    } else {
                        toastr.error( 'Došlo je do greške kod slanja!' )
                    }
                    vm.open_loader = false
                }, ( error )=> {
                    console.log(error);
                    toastr.error( 'Došlo je do greške kod slanja!' )
                    vm.open_loader = false
                })
            }
        },

        setDatatable() {

            let vm = this;

            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.skup_id
                            },
                        },
                        {
                            'id': 'ponuda_placena',
                            visible: false,
                            'filter': {
                                'value': [1]
                            },

                        },
                        {
                            'id': 'vrsta_kotizacije_id',
                            visible: false,
                            'filter': {
                                'value': [1]
                            },

                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'type' : function( row ){
                                return row.ime
                            },
                            'column_visibility_group': {
                                id: 'prijava-teme',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'type' : function( row ){
                                return row.prezime
                            },
                            'column_visibility_group': {
                                id: 'prijava-teme',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'E-mail',
                            'type' : function( row ){
                                return row.email
                            },
                            'column_visibility_group': {
                                id: 'prijava-teme',
                            }
                        },
                        {
                            'id': 'oib',
                            'title': 'OIB',
                            'type' : function( row ){
                                return row.oib
                            },
                            'column_visibility_group': {
                                id: 'prijava-osoba',
                            }
                        },
                        {
                            'id': 'posalji',
                            'title': 'Pošalji QR',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'qrs',
                            },
                            config: {
                                title: 'Pošalji',
                                type: 'primary',
                            }
                        },
                        {
                            'id': 'zaslanje',
                            'title': 'Za slanje',
                            'type' : function( row ){
                                return row.prijava_osoba_qrs.length ? (row.prijava_osoba_qrs[row.prijava_osoba_qrs.length - 1].red_cekanja ? 'Da' : 'Ne' ) : 'Ne'
                            },
                            searchable: false,
                            orderable: false,
                            'column_visibility_group': {
                                id: 'qrs',
                            }
                        },
                        {
                            'id': 'potvrdjeno',
                            'title': 'Potvrđeno',
                            'type' : function( row ){
                                return row.prijava_osoba_qrs.length ? (row.prijava_osoba_qrs[row.prijava_osoba_qrs.length - 1].potvrda_slanja ? 'Da' : 'Ne' ) : 'Ne'
                            },
                            searchable: false,
                            orderable: false,
                            'column_visibility_group': {
                                id: 'qrs',
                            }
                        },
                        {
                            'id': 'poslano',
                            'title': 'Poslano',
                            'type' : function( row ){
                                return row.prijava_osoba_qrs.length ? (row.prijava_osoba_qrs[row.prijava_osoba_qrs.length - 1].poslano ? 'Da' : 'Ne' ) : 'Ne'
                            },
                            searchable: false,
                            orderable: false,
                            'column_visibility_group': {
                                id: 'qrs',
                            }
                        },
                        {
                            'id': 'isporuceno',
                            'title': 'Isporučeno',
                            'type' : function( row ){
                                return row.prijava_osoba_qrs.length ? (row.prijava_osoba_qrs[row.prijava_osoba_qrs.length - 1].uspjesno ? 'Da' : 'Ne' ) : 'Ne'
                            },
                            searchable: false,
                            orderable: false,
                            'column_visibility_group': {
                                id: 'qrs',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'qrs' : 'QRs'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'QRs',
            }
            vm.show_datatable = true;
        },

        buttonClick( col, row ){
            if( col.id === 'posalji' ){
                this.send( row.id )
            }
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/qrs',
                    name: 'QRs'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
        this.interval = setInterval(() => this.setDatatable(), 100000);
    }
}
</script>

<style scoped>

</style>