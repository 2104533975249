<template>
    <div>
        <loading
                :active.sync="open_loader"
                :can-cancel="false"
                :is-full-page="true"
                :color="'red'"
        >
        </loading>


        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
        >
        </forms>

</div>

</template>

<script>
import toastr from 'toastr';
import Loading from "vue-loading-overlay";
import Forms from "@/components/form/Forms";
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
export default {
    name: "Forma28Forma",
    components: {
        Forms,
        Loading
    },
    data(){
        return {
            settings: {},
            errors: {},
            oib: this.$route.params.oib,

            open_loader: false,

            data: {},
            input_data: {},

            skup_id: 28,
            forma_id: 2
        }
    },

    methods: {

        init(){
            let vm = this
            vm.setupForm()
        },

        setupForm(){
            let vm = this;

            vm.buttons = []


            let button_save = {
                id: 'save',
                title: 'Pošalji',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + 'forma-data-28'
                }
            }



            let buttons = []
            buttons.push( button_save )


            let oib_input = {
                name: 'oib',
                type: 'select',
                title: 'Korisnik',
                value: {},
                width: 12,
                error: vm.errors['oib'] ? vm.errors['oib'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }
                        axios.get( process.env.VUE_APP_API_URL +  'korisnik', {
                            params:{
                                order: 'asc',
                                order_by: 'prezime',
                                page: 1,
                                per_page: -1
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.oib,
                                            value: ( item.prezime + ' ' + item.ime + ' | ' + item.oib )
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    multiple: false,
                    title_width: 4,
                    input_width: 8
                }
            }

            let skup_input = {
                name: 'skup_id',
                type: 'hidden',
                value: vm.skup_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }
            let forma_input = {
                name: 'forma_id',
                type: 'hidden',
                value: vm.forma_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }

            let privola1 = {
                key: vm.input_data.privola1
                        ? vm.input_data.privola1 ? '1' : '0'
                        : vm.data.privola1 ? '1' : '0',
                value: vm.input_data.privola1
                        ? ( vm.input_data.privola1 ? 'DA' : 'NE' )
                        : (
                                vm.data.privola1
                                        ? 'DA'
                                        : 'NE'
                        )
            }
            privola1 = {
                key: '',
                value: '',
            }
            let privola1_input = {
                name: 'privola1',
                type: 'select',
                title: 'Jeste li preboljeli Covid 19',
                value: privola1,
                width: 12,
                error: vm.errors['privola1'] ? vm.errors['privola1'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: [
                        {
                            key: '0',
                            value: 'NE'
                        },
                        {
                            key: '1',
                            value: 'DA'
                        },
                    ],
                    title_width: 10,
                    input_width: 2
                }
            }


            let privola2 = {
                key: vm.input_data.privola2
                        ? vm.input_data.privola2 ? '1' : '0'
                        : vm.data.privola2 ? '1' : '0',
                value: vm.input_data.privola2
                        ? ( vm.input_data.privola2 ? 'DA' : 'NE' )
                        : (
                                vm.data.privola2
                                        ? 'DA'
                                        : 'NE'
                        )
            }
            privola2 = {
                key: '',
                value: '',
            }
            let privola2_input = {
                name: 'privola2',
                type: 'select',
                title: 'Jeste li cijepljeni  najmanje jednom dozom cjepiva',
                value: privola2,
                width: 12,
                error: vm.errors['privola2'] ? vm.errors['privola2'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: [
                        {
                            key: '0',
                            value: 'NE'
                        },
                        {
                            key: '1',
                            value: 'DA'
                        },
                    ],
                    title_width: 10,
                    input_width: 2
                }
            }



            let privola3 = {
                key: vm.input_data.privola3
                        ? vm.input_data.privola3 ? '1' : '0'
                        : vm.data.privola3 ? '1' : '0',
                value: vm.input_data.privola3
                        ? ( vm.input_data.privola3 ? 'DA' : 'NE' )
                        : (
                                vm.data.privola3
                                        ? 'DA'
                                        : 'NE'
                        )
            }
            privola3 = {
                key: '',
                value: '',
            }
            let privola3_input = {
                name: 'privola3',
                type: 'select',
                title: 'Niste preboljeli Covid 19 ili se niste cijepili barem jednom dozom cjepiva, PRIHVAĆAM  antigenski test u hotelu Ambasador na trošak organizatora.',
                value: privola3,
                width: 12,
                error: vm.errors['privola3'] ? vm.errors['privola3'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: [
                        {
                            key: '0',
                            value: 'NE'
                        },
                        {
                            key: '1',
                            value: 'DA'
                        },
                    ],
                    title_width: 10,
                    input_width: 2
                }
            }



            let privola4 = {
                key: vm.input_data.privola4
                        ? vm.input_data.privola4 ? '1' : '0'
                        : vm.data.privola4 ? '1' : '0',
                value: vm.input_data.privola4
                        ? ( vm.input_data.privola4 ? 'DA' : 'NE' )
                        : (
                                vm.data.privola4
                                        ? 'DA'
                                        : 'NE'
                        )
            }
            privola4 = {
                key: '',
                value: '',
            }
            let privola4_input = {
                name: 'privola4',
                type: 'select',
                title: 'Ukoliko niste preboljeli Covid 19, niste cijepljeni i ne želite se besplatno testirati antigenskim testom u hotelu Ambasador  NEĆETE MOĆI SUDJELOVATI NA  15. Danima HKIG-a i u tom slučaju ja tražim povrat kotizacije.',
                value: privola4,
                width: 12,
                error: vm.errors['privola4'] ? vm.errors['privola4'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: [
                        {
                            key: '0',
                            value: 'NE'
                        },
                        {
                            key: '1',
                            value: 'DA'
                        },
                    ],
                    title_width: 10,
                    input_width: 2
                }
            }

            let inputs = []
            inputs.push( oib_input )
            inputs.push( skup_input )
            inputs.push( forma_input )
            inputs.push( privola1_input )
            inputs.push( privola2_input )
            inputs.push( privola3_input )
            inputs.push( privola4_input )


            /**
             * FORMA
             */
            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    this.$router.push({ path: '/skupovi/' + this.skup_id + '/forma-data/create' } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            }

        },
    },

    mounted() {
        this.init()
    },

}
</script>

<style scoped>

</style>

<style lang="scss">
.vertical-scrollbar {
    width: 100%;
    height: calc(100vh - 200px);
    flex-grow: 1;
    article {
        padding-bottom: 15px;
    }
}
</style>